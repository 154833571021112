import {
  ITEMS_DATA,
  TEMP_ITEM_DATA,
  DIALOG_TAGS_KEY,
  ITEM_TAGS_KEY,
  PERCENT,
  ADD_CATALOGUE_ITEM_TABLE,
  CATALOGUE_FILTERS_KEY,
  GROUPED_ITEMS_TABLE,
  ITEM_VARIANT_FILTER_KEY,
  GROUPED_ITEM_VARIANTS_TABLE,
  INITIAL_CATALOGUE_SETTINGS,
  ALL,
  WHOLESALE_PRICE_LABEL,
  RETAIL_PRICE_LABEL,
  GROUPED_UNIT,
  VENDOR_DESIGN_CODE,
  ITEM_GROUP_LABEL,
  ATTRIBUTE_1,
  ATTRIBUTE_2,
  ATTRIBUTE_3,
  PURCHASE_PRICE_LABEL,
  HIDDEN_ITEM_FILERS_KEY,
  DESCRIPTION,
  SHOW_ISHOP,
  LOW_STOCK_ALERT_QTY
} from '../constants';
import {
  TOGGLE_ADD_ITEMS,
  SET_ITEM_GROUP,
  SHOW_ITEM_GROUP_DIALOG,
  UPDATE_SELECTED_ITEM_GROUP,
  RESET_SELECTED_ITEM_GROUP,
  SET_ALL_ITEM_GROUP,
  SET_ITEM_PAYLOAD,
  SHOW_ITEM_SALES_PRICE,
  CANCEL_ITEM_SALES_PRICE,
  RESET_ITEM_PAYLOAD,
  RESET_VARIANT_ITEM,
  SET_ITEM_GROUP_SEARCH_TEXT,
  SHOW_SALES_PRICE_VALUE,
  SET_ITEM_ERROR,
  SET_ITEM_DATA,
  SET_ITEM_PROPERTY,
  SET_ITEMS_BY_GROUP,
  SET_TAB_VIEW_ITEM_GROUP,
  SET_AVAILABLE_QTY,
  SHOW_EDIT_ITEM_GROUP_DIALOG,
  SET_UPDATED_ITEM_GROUP,
  EDIT_ITEM_MODE,
  SET_UPDATED_ITEM,
  EXPAND_ITEMS,
  ADD_VOUCHER_PARTY_TO_ITEM,
  TOGGLE_ITEM_ALERT_DIALOG,
  GET_AVAILABLE_QTY_REQUESTED,
  GET_AVAILABLE_QTY_FAILED,
  // SET_ALL_ITEM_COLLECTIONS,
  // SET_ALL_ITEM_BASE_MATERIALS,
  // SET_ALL_ITEM_COLORS,
  TOGGLE_PERSIST_MODE,
  SET_PERSIST_PROPERTY,
  SET_IMAGE_LIST,
  UPDATE_ROUNDOFF_RANGE_VALUE,
  FETCH_TAXES_SUCCESS,
  ADD_RANGE_TO_ITEM_GROUP,
  DELETE_RANGE_FROM_ITEM_GROUP,
  UPDATE_RATE_CODE_VALUE,
  SET_ITEM_UNITS,
  RESET_TAB_VIEW_ITEM_GROUP,
  SET_WSP_ROUND_OFF_FLAG,
  SET_RSP_ROUND_OFF_FLAG,
  SET_SP_LOCK,
  FETCH_PARTY_DESIGN_CODES_SUCCESS,
  FETCH_PARTY_DESIGN_CODES_FAILED,
  FETCH_PARTY_DESIGN_CODES_REQUESTED,
  TOGGLE_ITEM_VARIANT,
  RESET_PERSIST_PROPERTY,
  SET_ITEM_FETCHED,
  SAVE_ITEM_GROUP_REQUESTED,
  SAVE_ITEM_GROUP_SUCCESS,
  SAVE_ITEM_GROUP_FAILURE,
  ADD_ITEM_ATTRIBUTE_FAILED,
  ADD_ITEM_ATTRIBUTE_REQUESTED,
  FETCH_ALL_ITEM_GROUP_REQUESTED,
  FETCH_ALL_ITEM_GROUP_SUCCESS,
  FETCH_ALL_ITEM_GROUP_FAILED,
  FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  SET_ATTRIBUTE_VALUE,
  SET_ITEM_ATTRIBUTE_CONFIG,
  UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG,
  ADD_ITEM_ATTRIBUTE_SUCCESS,
  SET_NAME_AND_CODE,
  FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS,
  FETCH_ITEM_ATTRIBUTE_VALUE_FAILED,
  FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  SET_ITEM_PARTY_ID,
  FETCH_ITEM_GROUPS_BY_HSN_REQUESTED,
  FETCH_ITEM_GROUPS_BY_HSN_SUCCESS,
  FETCH_ITEM_GROUPS_BY_HSN_FAILED,
  SET_ATTRIBUTE_FILTER,
  SET_ITEM_TYPE_FILTER,
  SET_ATTRIBUTE_FILTER_FROM_MENU,
  RESET_ATTRIBUTE_FILTER,
  SET_ITEM_GROUPS_FILTER,
  UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS,
  CLEAR_UPDATE_ATTRIBUTE_FILTER,
  ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  ADD_ITEM_ATTRIBUTE_VALUE_FAILED,
  UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED,
  UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED,
  FETCH_ITEMS_MASTER_REQUESTED,
  FETCH_ITEMS_MASTER_SUCCESS,
  FETCH_ITEMS_MASTER_FAILED,
  ITEM_CREATION_REQUESTED,
  ITEM_UPDATE_REQUESTED,
  ITEM_UPDATE_FAILED,
  ITEM_CREATION_FAILED,
  ITEM_CREATION_SUCCESS,
  ITEM_UPDATE_SUCCESS,
  HIDDEN_ITEM_LIST_REQUESTED,
  HIDDEN_ITEM_LIST_SUCCESS,
  HIDDEN_ITEM_LIST_FAILED,
  ADD_ITEM_TAG_FAILED,
  ADD_ITEM_TAG_REQUESTED,
  ADD_ITEM_TAG_SUCCESS,
  FETCH_ITEM_TAGS_REQUESTED,
  FETCH_ITEM_TAGS_SUCCESS,
  FETCH_ITEM_TAGS_FAILED,
  UPDATE_ITEM_TAG_SUCCESS,
  ADD_ITEM_TAG_IN_SELECTED,
  SET_ITEM_TAGS_FILTER,
  ADD_TAGS_TO_ITEMS_REQUESTED,
  ADD_TAGS_TO_ITEMS_SUCCESS,
  ADD_TAGS_TO_ITEMS_FAILED,
  UPDATE_ITEM_TAG_IN_SELECTED,
  UPDATE_ITEM_TAG_FAILED,
  UPDATE_ITEM_TAG_REQUESTED,
  DELETE_ITEM_TAG_FAILED,
  DELETE_ITEM_TAG_REQUESTED,
  DELETE_ITEM_TAG_SUCCESS,
  DELETE_ITEM_TAG_IN_SELECTED,
  REMOVE_TAGS_FROM_ITEMS_REQUESTED,
  REMOVE_TAGS_FROM_ITEMS_SUCCESS,
  REMOVE_TAGS_FROM_ITEMS_FAILED,
  SET_ITEM_TAGS,
  TOGGLE_QTY_FILTER,
  PROGRESS_TRACKER,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS,
  FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE,
  FETCH_CATALOGUE_ITEMS_REQUEST,
  FETCH_CATALOGUE_ITEMS_SUCCESS,
  FETCH_CATALOGUE_ITEMS_FAILURE,
  SET_SELECTED_CATALOGUE,
  REMOVE_CATALOGUE_ITEMS_REQUEST,
  REMOVE_CATALOGUE_ITEMS_SUCCESS,
  REMOVE_CATALOGUE_ITEMS_FAILURE,
  TOGGLE_EDIT_CATALOGUE_ITEMS_MODE,
  ADD_CATALOGUE_ITEMS_REQUEST,
  ADD_CATALOGUE_ITEMS_SUCCESS,
  ADD_CATALOGUE_ITEMS_FAILURE,
  RENAME_CATALOGUE_ITEMS_REQUEST,
  RENAME_CATALOGUE_ITEMS_SUCCESS,
  RENAME_CATALOGUE_ITEMS_FAILURE,
  DELETE_CATALOGUE_REQUEST,
  DELETE_CATALOGUE_SUCCESS,
  DELETE_CATALOGUE_FAILURE,
  TOGGLE_CATALOGUE_ACCESS_REQUEST,
  TOGGLE_CATALOGUE_ACCESS_SUCCESS,
  TOGGLE_CATALOGUE_ACCESS_FAILURE,
  FETCH_ITEMS_GROUPED_REQUESTED,
  FETCH_ITEMS_GROUPED_SUCCESS,
  FETCH_ITEMS_GROUPED_FAILED,
  FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED,
  FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS,
  FETCH_ITEMS_GROUPED_VARIANTS_FAILED,
  CATALOGUE_CREATE_REQUEST,
  CATALOGUE_CREATE_SUCCESS,
  CATALOGUE_CREATE_FAILURE,
  RESET_CATALOGUE_LIST,
  SET_ITEM_PRICE_FILTER_BY,
  SET_ITEM_PRICE_FILTER_VALUE,
  UPDATE_CATALOGUE_SETTINGS_REQUEST,
  UPDATE_CATALOGUE_SETTINGS_SUCCESS,
  UPDATE_CATALOGUE_SETTINGS_FAILURE,
  FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS,
  CHANGE_CATALOGUE_SETTINGS,
  SET_CATALOGUE_SETTINGS,
  SET_GROUPED_ITEM_UNITS,
  SET_ITEM_ATTRIBUTE_VALUE_KEYED,
  FETCH_ITEM_GROUP_REQUESTED,
  FETCH_ITEM_GROUP_SUCCESS,
  FETCH_ITEM_GROUP_FAILED,
  UPDATE_CATALOGUE_ITEM_POSITION_REQUESTED,
  UPDATE_CATALOGUE_ITEM_POSITION_SUCCESS,
  UPDATE_CATALOGUE_ITEM_POSITION_FAILED,
  RESET_ITEM_GROUP_PAYLOAD,
  SET_ISHOP_STATUS_FILTER,
  ITEM_SEARCH_TEXT,
  RESET_ITEM_GROUP_PAGE_NO,
  SET_ITEM_FILTER_TOGGLE_LIST,
  SET_ITEM_BULK_TOGGLE,
  RESET_BULK_ITEM,
  REMOVE_ITEM,
  SET_ITEM_BULK_SETTING,
  SET_BULK_ITEMS,
  SET_OUT_OF_STOCK_FILTER
} from '../actions/types';

import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { isAttributeInGroupConfig } from '../utils';
import { filter, reduce, find } from 'lodash';
const itemGroupSchema = {
  name: '',
  code: '',
  hsn: '',
  taxPercentage: '',
  autoGenerateCode: true,
  description: '',
  wholesaleMarkupBase: 'unitPurchasePrice',
  wholesaleMarkup: 0,
  retailMarkupBase: 'unitSellWholeSalePrice',
  retailMarkup: 0,
  roundOff: [
    {
      startValue: 0,
      endValue: '∞',
      precisionMultiplier: 0,
      roundOffMethod: ''
    }
  ],
  rateCode: {
    wsp1: 1,
    rsp1: 1,
    wsp2: 0,
    rsp2: 0,
    separator: '+',
    wspAccumulator1: '*',
    wspAccumulator2: '+',
    rspAccumulator1: '*',
    rspAccumulator2: '+'
  },
  itemAttributeConfig: {
    attributeOrder: []
  },
  isBasicGroup: false,
  isService: false
};
const itemAttributesInitial = {
  attributeList: [],
  addItemAttributeRequested: false,
  attributeValues: [],
  attributeValuesLoading: {},
  openAttributeValueForm: false,
  attributeValuesKeyed: {}
};

const itemGroupsInitial = {
  itemGroupsKeyed: {},
  itemGroupsKeyedLoading: {}
};

const itemCataloguesInitial = {
  selectedCatalogueItemsList: {
    loading: false,
    data: [],
    pageNo: 1,
    totalDataCount: 0
  },
  selectedCatalogue: { name: '', viewCount: 0, accessEnabled: false, url: '', settings: {} },
  openAttributeValueForm: false,
  catalogueItemChangeRequested: false,
  catalogueChangeRequested: false,
  catalogueItemPositionChangeRequested: false,
  deleteCatalogueRequested: false,
  toggleCatalogueAccessRequested: false,
  addItemsToCatalogueMode: false,
  createdCatalogueList: [],
  catalogueCreateRequested: false,
  updateCatalogueSettingsRequested: false,
  settings: INITIAL_CATALOGUE_SETTINGS,
  lastUsedCatalogueSettings: INITIAL_CATALOGUE_SETTINGS
};

const itemTagsInitial = {
  tagList: [],
  tagListLoading: false,
  addItemTagRequested: false,
  updateItemTagRequested: false,
  openTagValueForm: false,
  addTagsToItemsRequested: false,
  removeTagsFromItemsRequested: false
};

const initialAttrValue = {
  page: 1,
  hitsPerPage: 20,
  totalDataCount: 0,
  totalPages: 0,
  data: []
};
const variantDataInitial = {
  page: 1,
  hitsPerPage: 20,
  totalDataCount: 0,
  totalPages: 0,
  data: []
};

const initialState = {
  items: [],
  persistMode: false,
  persistData: {
    wholesaleMarkupBase: 'unitPurchasePrice',
    wholesaleMarkup: parseFloat(0).toFixed(2),
    retailMarkupBase: 'unitSellWholeSalePrice',
    retailMarkup: parseFloat(0).toFixed(2),
    toggleVariant: false
  },
  hiddenItemList: {
    loader: false,
    data: []
  },
  payload: {
    id: '',
    itemGroupId: '',
    name: '',
    itemCode: '',
    // itemCollectionId: '',
    // itemBaseMaterialId: '',
    // itemColorId: '',
    unit: '',
    skuBarcode: '',
    iBranchId: '',
    unitPurchasePrice: parseFloat(0).toFixed(2),
    unitSellWholeSalePrice: parseFloat(0).toFixed(2),
    unitSellRetailPrice: parseFloat(0).toFixed(2),
    exclusiveOfTax: true,
    includeMarkUp: true,
    wspAdjustment: 0,
    rspAdjustment: 0,
    wholesaleMarkupBase: 'unitPurchasePrice',
    wholesaleMarkup: parseFloat(0).toFixed(2),
    wholesaleDiscountValue: 0,
    wholesaleDiscountUnit: PERCENT,
    wholesaleDiscountAmount: 0,
    retailMarkupBase: 'unitSellWholeSalePrice',
    retailMarkup: parseFloat(0).toFixed(2),
    retailDiscountValue: 0,
    retailDiscountUnit: PERCENT,
    retailDiscountAmount: 0,
    storageLocation: '',
    warehouseLocation: '',
    description: '',
    openingStock: [],
    openingStockTotal: 0,
    partyDesignCode: '',
    partyId: '',
    isBasicType: true,
    images: [],
    rateCode: '',
    itemAttributes: [],
    attributeConfig: [],
    eShopVisibility: true,
    minOrderQty: '',
    maxOrderQty: '',
    lowStockAlertQty: '',
    unitOfOrderQty: '',
    groupedUnit: '',
    qtyPerGroupedUnit: ''
  },
  [ITEM_TAGS_KEY]: [],
  [DIALOG_TAGS_KEY]: [],
  RSPAutoRoundOff: false,
  WSPAutoRoundOff: false,
  SPLock: false,
  itemGroups: [],
  itemGroupsRequested: false,
  itemUnits: [],
  itemGroupedUnits: [],
  numericSkuBarcode: '',
  // itemCollections: [],
  // itemBaseMaterials: [],
  // itemColor: [],
  itemsByGroup: [],
  itemsCountByGroup: 0,
  itemsByGroupPageNo: 1,
  itemsMasterLoading: false,
  itemsGroupedLoading: false,
  [ADD_CATALOGUE_ITEM_TABLE]: {
    loading: false,
    data: [],
    pageNo: 1,
    totalDataCount: 0
  },
  [GROUPED_ITEMS_TABLE]: {
    loading: false,
    data: [],
    pageNo: 1,
    totalDataCount: 0
  },
  [GROUPED_ITEM_VARIANTS_TABLE]: {
    '': variantDataInitial
  },
  tabViewItemGroup: itemGroupSchema,
  _itemGroupSearchText: '',
  _selectedItemGroup: itemGroupSchema,
  // collectionType: {
  //   id: '',
  //   name: '',
  //   code: '',
  //   description: ''
  // },
  // baseMaterial: {
  //   name: '',
  //   code: '',
  //   description: ''
  // },
  // color: {
  //   name: '',
  //   code: '',
  //   description: ''
  // },
  itemError: {},
  itemServerError: [],
  party: {
    name: '',
    aliasName: '',
    id: ''
  },
  filters: {
    itemType: ALL,
    selectedAttributeValueIds: {},
    selectedItemGroups: [],
    selectedItemTags: [],
    showQty: false,
    selectedPriceFilterBy: null,
    selectedPriceFilterValue: {
      min: '',
      max: ''
    },
    ishopFilterStatus: ALL,
    outOfStockFilter: ALL
  },
  [CATALOGUE_FILTERS_KEY]: {
    itemType: 'all',
    selectedAttributeValueIds: {},
    selectedItemGroups: [],
    selectedItemTags: [],
    showQty: false,
    selectedPriceFilterBy: null,
    selectedPriceFilterValue: {
      min: '',
      max: ''
    }
  },
  [ITEM_VARIANT_FILTER_KEY]: {
    itemType: 'all',
    selectedAttributeValueIds: {},
    selectedItemGroups: [],
    selectedItemTags: [],
    showQty: false
  },
  [HIDDEN_ITEM_FILERS_KEY]: {
    itemType: 'all',
    selectedAttributeValueIds: {},
    selectedItemGroups: [],
    selectedItemTags: [],
    showQty: false
  },
  partyDesignCodes: [],
  itemGroupsByHsnLoading: false,
  itemGroupsByHsn: [],
  app: {
    onShowAddItems: false,
    onShowItemGroup: false,
    showItemSalesPrice: false,
    showSalesPriceValue: false,
    showAlertDialog: false
  },
  itemGroupSaveBtnDisable: false,
  addItemAttributeRequested: false,
  addUpdateAttributeValueRequested: false,
  itemCreateUpdateInProgress: false,
  progressTracker: {
    percentage: 0,
    isCompleted: true
  },
  cataloguePublicShareStatus: {
    loading: false,
    data: [],
    pageNo: 1,
    totalDataCount: 0
  },
  itemSearch: {
    barcodeSearchOn: false,
    searchText: '',
    hiddenItemSearchText: ''
  },
  itemFilterList: {
    showPartyFilter: { label: 'Party', id: 'showPartyFilter', checked: true },
    showItemGroupsFilter: { label: 'Item groups', id: 'showItemGroupsFilter', checked: true },
    showItemTypeFilter: { label: 'Basic/Advanced', id: 'showItemTypeFilter', checked: true }
  },
  bulkItemSetting: {
    [ITEM_GROUP_LABEL]: {
      hidden: false,
      autoRepeat: true,
      disabled: true
    },
    [ATTRIBUTE_1]: {
      hidden: false,
      autoRepeat: true,
      disabled: true
    },
    [ATTRIBUTE_2]: {
      hidden: true,
      autoRepeat: false
    },
    [ATTRIBUTE_3]: {
      hidden: true,
      autoRepeat: false
    },
    [PURCHASE_PRICE_LABEL]: {
      hidden: false,
      autoRepeat: true
    },
    [WHOLESALE_PRICE_LABEL]: {
      hidden: false,
      autoRepeat: true
    },
    [RETAIL_PRICE_LABEL]: {
      hidden: false,
      autoRepeat: true
    },
    [GROUPED_UNIT]: {
      hidden: true,
      autoRepeat: true
    },
    [VENDOR_DESIGN_CODE]: {
      hidden: true,
      autoRepeat: true
    },
    [DESCRIPTION]: {
      hidden: true,
      autoRepeat: false
    },
    [SHOW_ISHOP]: {
      hidden: true,
      autoRepeat: false
    },
    [LOW_STOCK_ALERT_QTY]: {
      hidden: true,
      autoRepeat: false
    }
  },
  bulkItemsList: []
};

const items = (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS_TRACKER:
      return {
        ...state,
        progressTracker: {
          percentage: action.payload.percentage,
          isCompleted: action.payload.isCompleted
        }
      };
    case HIDDEN_ITEM_LIST_REQUESTED:
      return {
        ...state,
        hiddenItemList: {
          ...state.hiddenItemList,
          loader: true
        }
      };
    case HIDDEN_ITEM_LIST_SUCCESS:
      return {
        ...state,
        hiddenItemList: {
          ...action.payload,
          loader: false
        }
      };
    case HIDDEN_ITEM_LIST_FAILED:
      return {
        ...state,
        hiddenItemList: {
          ...state.hiddenItemList,
          loader: false
        }
      };
    case ITEM_UPDATE_REQUESTED:
    case ITEM_CREATION_REQUESTED:
      return {
        ...state,
        itemCreateUpdateInProgress: true
      };
    case ITEM_UPDATE_SUCCESS:
    case ITEM_CREATION_SUCCESS:
    case ITEM_UPDATE_FAILED:
    case ITEM_CREATION_FAILED:
      return {
        ...state,
        itemCreateUpdateInProgress: false
      };
    case ITEMS_DATA:
      return { ...state, items: action.payload };
    case SET_ATTRIBUTE_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedAttributeValueIds: {
            ...state[action.storeKey].selectedAttributeValueIds,
            [action.attributeId]: action.attributeValueIds
          }
        }
      };
    case ITEM_SEARCH_TEXT:
      return {
        ...state,
        itemSearch: {
          ...state.itemSearch,
          [action.key]: action.value
        }
      };
    case RESET_ATTRIBUTE_FILTER:
      let tempAttribFilter = { ...state[action.storeKey].selectedAttributeValueIds };
      Object.keys(tempAttribFilter).forEach(function(key) {
        if (tempAttribFilter[key]) {
          tempAttribFilter[key] = null;
        }
      });
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedAttributeValueIds: tempAttribFilter,
          ishopFilterStatus: ALL,
          outOfStockFilter: ALL
        }
      };
    case SET_ATTRIBUTE_FILTER_FROM_MENU:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedAttributeValueIds: {
            ...state.filters.selectedAttributeValueIds,
            [action.attributeId]: [
              // ...state.filters.selectedAttributeValueIds[action.attributeId],
              action.attributeValue
            ]
          }
        }
      };
    case SET_ITEM_TAGS_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedItemTags: [...action.tags]
        }
      };
    case CLEAR_UPDATE_ATTRIBUTE_FILTER:
      let tempAttributeFilter = {};
      Object.keys(state[action.storeKey].selectedAttributeValueIds).forEach(function(key) {
        if (isAttributeInGroupConfig(action.selectedItemGroups, key)) {
          tempAttributeFilter[key] = state[action.storeKey].selectedAttributeValueIds[key];
        } else {
          tempAttributeFilter[key] = null;
        }
      });
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedAttributeValueIds: {
            ...tempAttributeFilter
          }
        }
      };
    case TOGGLE_QTY_FILTER:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          showQty: action.payload
        }
      };
    case SET_ITEM_PRICE_FILTER_BY:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedPriceFilterBy: action.priceFilterBy
        }
      };
    case SET_ITEM_PRICE_FILTER_VALUE:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedPriceFilterValue: {
            ...state[action.storeKey].selectedPriceFilterValue,
            [action.key]: action.priceFilterValue
          }
        }
      };
    case SET_ITEM_GROUPS_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedItemGroups: action.payload
        }
      };
    case SET_ITEM_TYPE_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          itemType: action.payload
        }
      };
    case SET_ISHOP_STATUS_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          ishopFilterStatus: action.payload
        }
      };
    case SET_OUT_OF_STOCK_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          outOfStockFilter: action.payload
        }
      };
    case TOGGLE_ADD_ITEMS:
      return {
        ...state,

        app: {
          ...state.app,
          onShowAddItems: !state.app.onShowAddItems
        }
      };
    case SET_IMAGE_LIST:
      return {
        ...state,
        payload: {
          ...state.payload,
          images: action.item
        }
      };
    case SET_WSP_ROUND_OFF_FLAG:
      return {
        ...state,
        WSPAutoRoundOff: action.payload
      };
    case SET_RSP_ROUND_OFF_FLAG:
      return {
        ...state,
        RSPAutoRoundOff: action.payload
      };
    case SET_SP_LOCK:
      return {
        ...state,
        SPLock: action.payload
      };
    case EDIT_ITEM_MODE:
      const partyId = action.item.partyId
        ? action.item.partyId
        : action.item.party
        ? action.item.party.id
        : '';
      const selectedItemGroup = state.itemGroups.find(item => item.id === action.item.itemGroupId);
      const docs =
        action.item.images.length > 0 && action.variant
          ? action.item.images.map(img => {
              delete img.id;
              return img;
            })
          : action.item.images;
      return {
        ...state,
        payload: {
          isBasicType: action.item.isBasicType,
          id: action.item.id ? action.item.id : '',
          itemGroupId: action.item.itemGroupId,
          name: action.item.name,
          itemCode: action.item.itemCode,
          // itemCollectionId: collectionId,
          // itemBaseMaterialId: materialId,
          // itemColorId: colorId,
          partyId: partyId,
          unit: action.item.unit,
          skuBarcode: action.item.skuBarcode,
          iBranchId: '',
          unitPurchasePrice: action.item.unitPurchasePrice || 0,
          unitSellWholeSalePrice: action.item.unitSellWholeSalePrice,
          unitSellRetailPrice: action.item.unitSellRetailPrice,
          exclusiveOfTax: true,
          includeMarkUp:
            action.item.includeMarkUp === true || action.item.includeMarkUp === false //if includeMarkUp not avaliable
              ? action.item.includeMarkUp
              : partyId
              ? true
              : false, //for old items  without includeMarkUp field check if party is added
          wholesaleMarkupBase: 'unitPurchasePrice',
          wholesaleMarkup: action.item.wholesaleMarkup,
          wholesaleDiscountValue: action.item.wholesaleDiscountValue,
          wholesaleDiscountUnit: action.item.wholesaleDiscountUnit,
          wholesaleDiscountAmount: action.item.wholesaleDiscountAmount,
          retailDiscountValue: action.item.retailDiscountValue,
          retailDiscountUnit: action.item.retailDiscountUnit,
          retailDiscountAmount: action.item.retailDiscountAmount,
          retailMarkupBase: action.item.retailMarkupBase,
          retailMarkup: action.item.retailMarkup,
          storageLocation: '',
          warehouseLocation: '',
          isHidden: action.item.isHidden || false,
          description: action.item.description,
          rateCode: action.item.rateCode ? action.item.rateCode : '-',
          partyDesignCode: action.item.partyDesignCode || '',
          openingStock: action.variant ? [] : action.item.openingStock || [],
          openingStockTotal: action.variant
            ? 0
            : action.item.openingStock
            ? action.item.openingStock.reduce(
                (accumulator, currentValue) => accumulator + Number(currentValue.qty),
                0
              )
            : 0,
          images: docs || [],
          wspAdjustment: action.item.wspAdjustment || 0,
          rspAdjustment: action.item.rspAdjustment || 0,
          itemAttributes: action.item.itemAttributes,
          attributeConfig: action.item.attributeConfig,
          eShopVisibility: action.item.eShopVisibility,
          minOrderQty: action.item.minOrderQty,
          maxOrderQty: action.item.maxOrderQty,
          lowStockAlertQty: action.item.lowStockAlertQty,
          unitOfOrderQty: action.item.unitOfOrderQty,
          groupedUnit: action.item.groupedUnit,
          qtyPerGroupedUnit: action.item.qtyPerGroupedUnit
        },
        numericSkuBarcode: action.variant ? '' : action.item.numericSkuBarcode,
        tags: action.item.tags || [],
        RSPAutoRoundOff: action.item.rspAdjustment !== 0,
        WSPAutoRoundOff: action.item.wspAdjustment !== 0,
        SPLock: action.item.id ? true : false,
        party: action.item.party ? action.item.party : initialState.party,
        // collectionType:
        //   state.itemCollections.length > 0 && collectionId !== ''
        //     ? state.itemCollections.find(coll => coll.id === collectionId)
        //     : initialState.collectionType,
        // color:
        //   state.itemColor.length > 0 && colorId !== ''
        //     ? state.itemColor.find(col => col.id === colorId)
        //     : initialState.color,
        // baseMaterial:
        //   state.itemBaseMaterials.length > 0 && materialId !== ''
        //     ? state.itemBaseMaterials.find(base => base.id === materialId)
        //     : initialState.baseMaterial,
        app: {
          ...state.app,
          onShowAddItems: action.variant ? state.app.onShowAddItems : true
        },
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          ...selectedItemGroup
        }
      };
    case SET_ITEM_GROUP:
      return {
        ...state,
        _selectedItemGroup: action.payload.newItemGroup
          ? { ...state._selectedItemGroup, ...action.payload.newItemGroup }
          : { ...state._selectedItemGroup, ...action.payload._selectedItemGroup },
        tabViewItemGroup: action.payload.newItemGroup
          ? { ...state._selectedItemGroup, ...action.payload.newItemGroup }
          : { ...state._selectedItemGroup, ...action.payload._selectedItemGroup },
        itemGroups: action.payload.newItemGroup
          ? [...state.itemGroups, action.payload.newItemGroup]
          : [...state.itemGroups],
        payload: {
          ...state.payload,
          ...action.payload.nameAndCode,
          itemGroupId: action.payload.newItemGroup
            ? action.payload.newItemGroup.id
            : action.payload._selectedItemGroup.id,
          attributeConfig: initialState.payload.attributeConfig,
          itemAttributes: initialState.payload.itemAttributes
        }
      };
    case SHOW_ITEM_GROUP_DIALOG:
      return {
        ...state,
        _selectedItemGroup: {
          ...initialState._selectedItemGroup,
          name: action.name || ''
        },
        app: {
          ...state.app,
          onShowItemGroup: action.flag
        }
      };
    case SHOW_EDIT_ITEM_GROUP_DIALOG:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          ...state.tabViewItemGroup
        },
        app: {
          ...state.app,
          onShowItemGroup: !state.app.onShowItemGroup
        }
      };
    case 'CLOSE_ITEM_GROUP_DRAWER':
      return {
        ...state,
        _selectedItemGroup: itemGroupSchema,
        tabViewItemGroup: itemGroupSchema,
        app: {
          ...state.app,
          onShowItemGroup: false
        }
      };
    case SAVE_ITEM_GROUP_REQUESTED:
      return {
        ...state,
        itemGroupSaveBtnDisable: true
      };
    case SAVE_ITEM_GROUP_SUCCESS:
      return {
        ...state,
        itemGroupSaveBtnDisable: false
      };
    case SAVE_ITEM_GROUP_FAILURE:
      return {
        ...state,
        itemGroupSaveBtnDisable: false
      };
    case UPDATE_SELECTED_ITEM_GROUP:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          ...action.details
        }
      };
    case UPDATE_SELECTED_ITEM_GROUP_ATTRIBUTE_CONFIG:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          itemAttributeConfig: {
            ...state._selectedItemGroup.itemAttributeConfig,
            attributeOrder: action.configIds
          }
        }
      };
    case UPDATE_RATE_CODE_VALUE:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          rateCode: {
            ...state._selectedItemGroup.rateCode,
            ...action.details
          }
        }
      };
    case UPDATE_ROUNDOFF_RANGE_VALUE:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          roundOff: action.payload
        }
      };
    case ADD_RANGE_TO_ITEM_GROUP:
      let currentRoundOff = cloneDeep(state._selectedItemGroup.roundOff);
      currentRoundOff.push({
        startValue: 0,
        endValue: '∞',
        precisionMultiplier: 0,
        roundOffMethod: ''
      });
      currentRoundOff[0].endValue = 0;
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          roundOff: currentRoundOff
        }
      };
    case DELETE_RANGE_FROM_ITEM_GROUP:
      currentRoundOff = cloneDeep(state._selectedItemGroup.roundOff);
      currentRoundOff.splice(1, 1);
      currentRoundOff[0].endValue = '∞';
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          roundOff: currentRoundOff
        }
      };
    case RESET_SELECTED_ITEM_GROUP:
      return {
        ...state,
        _selectedItemGroup: {
          ...initialState._selectedItemGroup
        }
      };
    case RESET_TAB_VIEW_ITEM_GROUP:
      return {
        ...state,
        tabViewItemGroup: itemGroupSchema,
        _selectedItemGroup: itemGroupSchema
      };
    case SET_ALL_ITEM_GROUP:
      return {
        ...state,
        itemGroups: action.payload
      };
    case FETCH_ALL_ITEM_GROUP_REQUESTED:
      return {
        ...state,
        itemGroupsRequested: true
      };
    case FETCH_ALL_ITEM_GROUP_SUCCESS:
      return {
        ...state,
        itemGroupsRequested: false
      };
    case FETCH_ALL_ITEM_GROUP_FAILED:
      return {
        ...state,
        itemGroupsRequested: false
      };

    // case SET_ALL_ITEM_COLLECTIONS:
    //   return {
    //     ...state,
    //     itemCollections: action.payload
    //   };
    // case SET_ALL_ITEM_BASE_MATERIALS:
    //   return {
    //     ...state,
    //     itemBaseMaterials: action.payload
    //   };
    // case SET_ALL_ITEM_COLORS:
    //   return {
    //     ...state,
    //     itemColor: action.payload
    //   };
    case SET_ITEM_UNITS:
      return {
        ...state,
        itemUnits: action.payload
      };

    case SET_GROUPED_ITEM_UNITS:
      return {
        ...state,
        itemGroupedUnits: action.payload
      };
    case SET_ITEM_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.details
        }
      };
    case SET_ITEM_TAGS:
      return {
        ...state,
        [action.key]: action.tags
      };
    case SET_PERSIST_PROPERTY:
      return {
        ...state,
        persistData: {
          ...state.persistData,
          ...action.details
        }
      };
    case RESET_PERSIST_PROPERTY:
      return {
        ...state,
        persistData: {
          ...initialState.persistData
        }
      };
    case TOGGLE_PERSIST_MODE:
      return {
        ...state,
        persistMode: action.payload
      };

    case TOGGLE_ITEM_VARIANT:
      return {
        ...state,
        persistData: {
          ...state.persistData,
          toggleVariant: action.payload
        }
      };
    case SHOW_ITEM_SALES_PRICE:
      return {
        ...state,
        app: {
          ...state.app,
          showItemSalesPrice: !state.app.showItemSalesPrice
        }
      };
    case CANCEL_ITEM_SALES_PRICE:
      return {
        ...state,
        payload: {
          ...state.payload,
          unitSellWholeSalePrice: 0,
          unitSellRetailPrice: 0,
          wholesaleMarkup: 0,
          retailMarkup: 0
        },
        app: {
          ...state.app,
          showSalesPriceValue: false
        }
      };
    case RESET_VARIANT_ITEM:
      return {
        ...state,
        payload: {
          ...initialState.payload,
          attributeConfig: state.payload.attributeConfig,
          itemAttributes: map(state.payload.attributeConfig, (configId, index) => {
            return null;
          }),
          itemGroupId: action.match ? state.payload.itemGroupId : initialState.payload.itemGroupId
        },
        _selectedItemGroup: action.match
          ? { ...state._selectedItemGroup }
          : {
              ...initialState._selectedItemGroup
            },
        _itemGroupSearchText: '',
        // collectionType: {
        //   ...initialState.collectionType
        // },
        // baseMaterial: {
        //   ...initialState.baseMaterial
        // },
        // color: {
        //   ...initialState.color
        // },

        party: {
          ...initialState.party
        },
        partyDesignCodes: []
      };
    case RESET_ITEM_PAYLOAD:
      const { persistData, persistMode } = state;
      const initPayload = persistMode
        ? { ...initialState.payload, ...persistData }
        : initialState.payload;
      return {
        ...state,
        SPLock: initialState.SPLock,
        payload: {
          ...initPayload
        },
        numericSkuBarcode: '',
        _selectedItemGroup: {
          ...initialState._selectedItemGroup
        },
        _itemGroupSearchText: '',
        addUpdateAttributeValueRequested: initialState.addUpdateAttributeValueRequested,
        // collectionType: {
        //   ...initialState.collectionType
        // },
        // baseMaterial: {
        //   ...initialState.baseMaterial
        // },
        // color: {
        //   ...initialState.color
        // },
        party: {
          ...initialState.party
        },
        app: {
          ...initialState.app
        },
        partyDesignCodes: [],
        tags: []
      };
    case SET_ITEM_GROUP_SEARCH_TEXT:
      return {
        ...state,
        _itemGroupSearchText: action.searchText
      };
    case SHOW_SALES_PRICE_VALUE:
      return {
        ...state,
        app: {
          ...state.app,
          showSalesPriceValue: !state.app.showSalesPriceValue
        }
      };
    case SET_ITEM_DATA:
      let createdItem = { ...action.item };
      createdItem.party = state.party;
      // createdItem.itemCollection = state.collectionType;
      // createdItem.itemBaseMaterial = state.baseMaterial;
      // createdItem.itemColor = state.color;
      createdItem.expand = false;
      createdItem.sameItems = [];
      // const itemsByGroup = state.itemsByGroup.map(
      //   element =>
      //     element.itemCode === createdItem.itemCode
      //       ? {
      //           ...element,
      //           sameItems: [...element.sameItems, createdItem]
      //         }
      //       : element
      // );
      return {
        ...state,
        // itemsByGroup:
        //   _.findIndex(state.itemsByGroup, item => item.itemCode === createdItem.itemCode) >= 0
        //     ? itemsByGroup
        //     : [...itemsByGroup, createdItem]
        itemsByGroup: [...state.itemsByGroup, createdItem]
      };

    case GET_AVAILABLE_QTY_REQUESTED: {
      const tempitemList = cloneDeep(action.key ? state[action.key].data : state.itemsByGroup);
      tempitemList.forEach(item => {
        if (item.id === action.itemId) {
          item.loader = true;
        }
      });
      return {
        ...state,
        ...(action.key
          ? {
              [action.key]: {
                ...state[action.key],
                data: tempitemList
              }
            }
          : {
              itemsByGroup: tempitemList
            })
      };
    }

    case GET_AVAILABLE_QTY_FAILED: {
      const tempitemList = cloneDeep(action.key ? state[action.key].data : state.itemsByGroup);
      tempitemList.forEach(item => {
        if (item.id === action.itemId) {
          item.loader = false;
        }
      });
      return {
        ...state,
        ...(action.key
          ? {
              [action.key]: {
                ...state[action.key],
                data: tempitemList
              }
            }
          : {
              itemsByGroup: tempitemList
            })
      };
    }

    case SET_AVAILABLE_QTY: {
      const tempitemList = cloneDeep(action.key ? state[action.key].data : state.itemsByGroup);
      tempitemList.forEach(item => {
        if (item.id === action.itemId) {
          item.loader = false;
          item.currentQty = {
            ...action.payload
          };
        }
      });
      return {
        ...state,
        ...(action.key
          ? {
              [action.key]: {
                ...state[action.key],
                data: tempitemList
              }
            }
          : {
              itemsByGroup: tempitemList
            })
      };
    }
    case SET_ITEM_ERROR:
      return {
        ...state,
        itemError: {
          ...state.itemError,
          ...action.details
        }
      };
    case FETCH_ITEM_GROUPS_BY_HSN_REQUESTED:
      return {
        ...state,
        itemGroupsByHsnLoading: true
        // itemGroupsByHsn: []
      };
    case FETCH_ITEM_GROUPS_BY_HSN_SUCCESS:
      return {
        ...state,
        itemGroupsByHsnLoading: false,
        itemGroupsByHsn: action.payload
      };
    case FETCH_ITEM_GROUPS_BY_HSN_FAILED:
      return {
        ...state,
        itemGroupsByHsnLoading: false,
        itemGroupsByHsn: []
      };
    case SET_ITEM_PROPERTY:
      return {
        ...state,
        ...action.details.propertyData,
        payload: {
          ...state.payload,
          ...action.details.nameAndCode
        }
      };
    case SET_ITEM_PARTY_ID:
      return {
        ...state,
        payload: {
          ...state.payload,
          partyId: action.partyId
        }
      };
    case SET_ITEM_FETCHED:
      return {
        ...state
      };
    case SET_ITEMS_BY_GROUP:
      // let result = _.chain(action.payload)
      //   .groupBy('itemCode')
      //   .map((items, i) => {
      //     return {
      //       ...items[0],
      //       expand: false,
      //       name: items[0].name.replace(state.tabViewItemGroup.name, ''),
      //       // party: items[0].party ? items[0].party : initialState.party,
      //       sameItems: _.filter(items, (item, index) => {
      //         if (index > 0) {
      //           item.name = item.name.replace(state.tabViewItemGroup.name, '');
      //           item.expand = false;
      //           item.sameItems = [];
      //           item.party = item.party ? item.party : initialState.party;
      //           return item;
      //         }
      //       })
      //     };
      //   })
      //   .value();
      // console.log(result);
      return {
        ...state,
        ...(action.key
          ? {
              [action.key]: {
                ...state[action.key],
                loading: false,
                data: action.payload.data,
                pageNo: action.payload.page,
                totalDataCount: action.payload.totalDataCount
              }
            }
          : {
              itemsByGroup: action.payload.data,
              itemsCountByGroup: action.payload.totalDataCount || 0,
              itemsByGroupPageNo: action.payload.page
            })
      };
    case FETCH_ITEMS_MASTER_REQUESTED:
      return {
        ...state,
        itemsMasterLoading: true
      };
    case FETCH_ITEMS_MASTER_SUCCESS:
      return {
        ...state,
        itemsMasterLoading: false
      };
    case FETCH_ITEMS_MASTER_FAILED:
      return {
        ...state,
        itemsMasterLoading: false
      };
    case FETCH_ITEMS_GROUPED_REQUESTED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          data: [],
          loading: true
        }
      };
    case FETCH_ITEMS_GROUPED_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          data: action.payload.data,
          pageNo: action.payload.page,
          totalDataCount: action.payload.totalDataCount
        }
      };
    case FETCH_ITEMS_GROUPED_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false
        }
      };
    case FETCH_ITEMS_GROUPED_VARIANTS_REQUESTED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          [action.itemAttributeValueId]: {
            ...state[action.key][action.itemAttributeValueId],
            data: action.page === 1 ? [] : state[action.key][action.itemAttributeValueId].data,
            loading: true
          }
        }
      };
    case FETCH_ITEMS_GROUPED_VARIANTS_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          [action.itemAttributeValueId]: {
            ...state[action.key][action.itemAttributeValueId],
            loading: false,
            data:
              action.payload.page === 1
                ? action.payload.data
                : [...state[action.key][action.itemAttributeValueId].data, ...action.payload.data],
            page: action.payload.page,
            totalDataCount: action.payload.totalDataCount,
            totalPages: action.payload.totalPages
          }
        }
      };
    case FETCH_ITEMS_GROUPED_VARIANTS_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          [action.itemAttributeValueId]: {
            ...state[action.key][action.itemAttributeValueId],
            data: [],
            loading: false
          }
        }
      };
    case SET_TAB_VIEW_ITEM_GROUP:
      return {
        ...state,
        tabViewItemGroup: {
          ...state.tabViewItemGroup,
          ...action.payload,
          isBasicGroup: action.payload.isBasicGroup || false //for old groups that dont have isBasicGroup value
        },
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          ...action.payload,
          isBasicGroup: action.payload.isBasicGroup || false //for old groups that dont have isBasicGroup value
        }
      };
    case SET_UPDATED_ITEM_GROUP:
      return {
        ...state,
        tabViewItemGroup: {
          ...state.tabViewItemGroup,
          ...action.payload
        },
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          ...action.payload
        },
        itemGroups: state.itemGroups.map(item =>
          item.id === action.payload.id ? { ...action.payload, itemCount: item.itemCount } : item
        ),
        app: {
          ...state.app,
          onShowItemGroup: !state.app.onShowItemGroup
        }
      };
    case SET_UPDATED_ITEM:
      return {
        ...state,
        itemsByGroup: state.itemsByGroup.map(item =>
          item.id === action.item.id
            ? {
                ...action.item,
                name: action.item.name.replace(state._selectedItemGroup.name, ''),
                party: state.party,
                // itemCollection: state.collectionType,
                // itemBaseMaterial: state.baseMaterial,
                // itemColor: state.color,
                sameItems: item.sameItems,
                expand: item.expand
              }
            : {
                ...item,
                party: item.party,
                // itemCollection: item.itemCollection,
                // itemBaseMaterial: item.itemBaseMaterial,
                // itemColor: item.itemColor,
                expand: item.expand
                // sameItems: item.sameItems.map(
                //   element =>
                //     element.id === action.item.id
                //       ? {
                //           ...action.item,
                //           party: state.party,
                //           itemCollection: state.collectionType,
                //           itemBaseMaterial: state.baseMaterial,
                //           itemColor: state.color
                //         }
                //       : element
                // )
              }
        ),
        app: {
          ...state.app
        }
      };
    case EXPAND_ITEMS:
      return {
        ...state,
        itemsByGroup: state.itemsByGroup.map((item, index) =>
          index === action.index ? { ...item, expand: !item.expand } : item
        )
      };
    case ADD_VOUCHER_PARTY_TO_ITEM:
      const storedData = state.persistMode ? state.persistData : {};
      return {
        ...state,
        payload: {
          ...state.payload,
          ...storedData,
          partyId: action.party.id ? action.party.id : ''
        },
        party: {
          ...action.party,
          id: action.party.id ? action.party.id : '',
          name: action.party.name ? action.party.name : ''
        },
        app: {
          ...state.app,
          onShowAddItems: !state.app.onShowAddItems
        }
      };
    case TOGGLE_ITEM_ALERT_DIALOG:
      return {
        ...state,
        app: {
          ...state.app,
          showAlertDialog: !state.app.showAlertDialog
        },
        itemServerError: action.error
      };
    case FETCH_TAXES_SUCCESS:
      return {
        ...state,
        taxes: action.payload
      };
    case FETCH_PARTY_DESIGN_CODES_SUCCESS:
      return {
        ...state,
        partyDesignCodes: action.payload
      };
    case FETCH_PARTY_DESIGN_CODES_FAILED:
      return {
        ...state,
        partyDesignCodes: []
      };
    case FETCH_PARTY_DESIGN_CODES_REQUESTED:
      return {
        ...state,
        partyDesignCodes: []
      };
    //item attribute related
    case ADD_ITEM_ATTRIBUTE_VALUE_REQUESTED:
    case UPDATE_ITEM_ATTRIBUTE_VALUE_REQUESTED:
      return {
        ...state,
        addUpdateAttributeValueRequested: true
      };
    case ADD_ITEM_ATTRIBUTE_VALUE_SUCCESS:
      return {
        ...state,
        addUpdateAttributeValueRequested: false,
        payload: {
          ...state.payload,
          itemAttributes: map(state.payload.itemAttributes, (attr, index) => {
            return index === action.index ? action.payload.id || null : attr;
          })
        }
      };
    case ADD_ITEM_ATTRIBUTE_VALUE_FAILED:
    case UPDATE_ITEM_ATTRIBUTE_VALUE_FAILED:
      return {
        ...state,
        addUpdateAttributeValueRequested: false
      };
    case SET_ATTRIBUTE_VALUE:
      return {
        ...state,
        payload: {
          ...state.payload,
          itemAttributes: map(state.payload.itemAttributes, (attr, index) => {
            return index === action.index ? action.payload.id || null : attr;
          })
        }
      };
    case SET_NAME_AND_CODE:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.nameAndCode
        }
      };
    case SET_ITEM_ATTRIBUTE_CONFIG:
      return {
        ...state,
        payload: {
          ...state.payload,
          attributeConfig: action.config,
          itemAttributes: map(action.config, (configId, index) => {
            return state.payload.itemAttributes[index] ? state.payload.itemAttributes[index] : null;
          })
        }
      };
    case RESET_ITEM_GROUP_PAYLOAD:
      return {
        ...state,
        payload: {
          ...itemGroupSchema
        }
      };
    case FETCH_ITEM_GROUP_ATTRIBUTE_CONFIG_SUCCESS:
      return {
        ...state,
        _selectedItemGroup: {
          ...state._selectedItemGroup,
          itemAttributeConfig: {
            ...state._selectedItemGroup.itemAttributeConfig,
            attributeOrder: action.payload.attributeOrder
          }
        }
      };
    case UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS:
      return {
        ...state,
        addUpdateAttributeValueRequested: false,
        filters: {
          ...state.filters,
          selectedAttributeValueIds: {
            ...state.filters.selectedAttributeValueIds,
            [action.key]: map(state.filters.selectedAttributeValueIds[action.key], attrVal =>
              attrVal.id === action.response.id
                ? {
                    ...attrVal,
                    label: action.response.name
                  }
                : attrVal
            )
          }
        }
      };
    case ADD_ITEM_TAG_IN_SELECTED:
      return {
        ...state,
        [action.storeKey]: map(state[action.storeKey], (item, index) =>
          index === state[action.storeKey].length - 1 && item.name === action.payload.name
            ? action.payload
            : item
        )
      };
    case UPDATE_ITEM_TAG_IN_SELECTED:
      return {
        ...state,
        [action.storeKey]: map(state[action.storeKey], (item, index) =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    case DELETE_ITEM_TAG_IN_SELECTED:
      return {
        ...state,
        [action.storeKey]: filter(state[action.storeKey], (item, index) => item.id !== action.tagId)
      };
    case ADD_TAGS_TO_ITEMS_SUCCESS:
      return {
        ...state,
        tags: reduce(
          action.payload[0],
          (prev, tag) => {
            const tagFound = find(prev, prevTag => prevTag.id === tag.id);
            if (tagFound) {
              return prev;
            } else {
              prev.push(tag);
              return prev;
            }
          },
          state.tags
        )
      };
    case REMOVE_TAGS_FROM_ITEMS_SUCCESS:
      return {
        ...state,
        tags: reduce(
          state.tags,
          (prev, tag) => {
            const tagFound = find(action.payload, payloadTag => payloadTag.id === tag.id);
            if (tagFound) {
              return prev;
            } else {
              prev.push(tag);
              return prev;
            }
          },
          []
        )
      };
    case FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_REQUEST:
      return {
        ...state,
        cataloguePublicShareStatus: {
          ...state.cataloguePublicShareStatus,
          loading: true
        }
      };

    case FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        cataloguePublicShareStatus: {
          ...state.cataloguePublicShareStatus,
          loading: false,
          data: action.data,
          pageNo: action.response.page,
          totalDataCount: action.response.totalDataCount
        }
      };
    case FETCH_ITEM_CATALOGUE_PUBLIC_SHARE_FAILURE:
      return {
        ...state,
        cataloguePublicShareStatus: {
          ...state.cataloguePublicShareStatus,
          loading: false
        }
      };
    case DELETE_CATALOGUE_SUCCESS:
      return {
        ...state,
        cataloguePublicShareStatus: {
          ...state.cataloguePublicShareStatus,
          data: filter(
            state.cataloguePublicShareStatus.data,
            catalogue => catalogue._id !== action.shareCatalogueId
          )
        }
      };
    case TOGGLE_CATALOGUE_ACCESS_SUCCESS:
      return {
        ...state,
        cataloguePublicShareStatus: {
          ...state.cataloguePublicShareStatus,
          data: map(state.cataloguePublicShareStatus.data, catalogue =>
            catalogue._id === action.shareCatalogueId
              ? { ...catalogue, accessEnabled: action.accessEnabled }
              : catalogue
          )
        }
      };
    case RESET_ITEM_GROUP_PAGE_NO:
      return {
        ...state,
        itemsByGroupPageNo: 1,
        itemsCountByGroup: 0
      };
    case SET_ITEM_FILTER_TOGGLE_LIST:
      return {
        ...state,
        itemFilterList: action.itemSettings
      };
    case SET_ITEM_BULK_SETTING:
      let setting = {};
      action.data.forEach(d => {
        setting[d.key] = {
          ...d
        };
        delete setting[d.key].key;
      });
      return {
        ...state,
        bulkItemSetting: {
          ...state.bulkItemSetting,
          ...setting
        }
      };
    case SET_ITEM_BULK_TOGGLE:
      return {
        ...state,
        bulkItemSetting: {
          ...state.bulkItemSetting,
          [action.key]: {
            ...state.bulkItemSetting[action.key],
            [action.settingKey]: action.value
          }
        }
      };
    case SET_BULK_ITEMS:
      return {
        ...state,
        bulkItemsList: action.data
      };
    case REMOVE_ITEM:
      let tempItems = cloneDeep(state.bulkItemsList);
      tempItems.splice(action.index, 1);
      tempItems = tempItems.map((item, i) => ({ ...item, index: i }));
      return {
        ...state,
        bulkItemsList: tempItems
      };
    case RESET_BULK_ITEM:
      return {
        ...state,
        bulkItemsList: action.data
      };
    default:
      return state;
  }
};

const itemAttributes = (state = itemAttributesInitial, action) => {
  switch (action.type) {
    case ADD_ITEM_ATTRIBUTE_FAILED:
      return {
        ...state,
        addItemAttributeRequested: false
      };
    case ADD_ITEM_ATTRIBUTE_REQUESTED:
      return {
        ...state,
        addItemAttributeRequested: true
      };
    case ADD_ITEM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        addItemAttributeRequested: false
      };
    case FETCH_ITEM_ATTRIBUTE_VALUE_REQUESTED:
      return {
        ...state,
        attributeValuesLoading: {
          ...state.attributeValuesLoading,
          [action.key]: true
        },
        attributeValues: {
          ...state.attributeValues,
          [action.key]: action.page === 1 ? initialAttrValue : state.attributeValues[action.key]
        }
      };
    case FETCH_ITEM_ATTRIBUTE_VALUE_SUCCESS:
      return {
        ...state,
        attributeValues: {
          ...state.attributeValues,
          [action.key]: {
            page: parseInt(action.payload.page),
            hitsPerPage: action.payload.hitsPerPage,
            totalDataCount: action.payload.totalDataCount,
            totalPages: action.payload.totalPages,
            data:
              parseInt(action.payload.page) === 1
                ? action.payload.data
                : [...state.attributeValues[action.key].data, ...action.payload.data]
          }
        },
        attributeValuesLoading: {
          ...state.attributeValuesLoading,
          [action.key]: false
        }
      };
    case FETCH_ITEM_ATTRIBUTE_VALUE_FAILED:
      return {
        ...state,
        attributeValuesLoading: {
          ...state.attributeValuesLoading,
          [action.key]: false
        }
      };
    case UPDATE_ITEM_ATTRIBUTE_VALUE_SUCCESS:
      return {
        ...state,
        attributeValues: {
          ...state.attributeValues,
          [action.key]: map(state.attributeValues[action.key], attrVal =>
            attrVal.id === action.response.id ? { ...action.response } : attrVal
          )
        }
      };
    case SET_ITEM_ATTRIBUTE_VALUE_KEYED:
      return {
        ...state,
        attributeValuesKeyed: {
          ...state.attributeValuesKeyed,
          [action.key]: {
            ...(state.attributeValuesKeyed[action.key]
              ? state.attributeValuesKeyed[action.key]
              : {}),
            [action.attribute.id]: action.attribute
          }
        }
      };
    default:
      return state;
  }
};

const itemGroups = (state = itemGroupsInitial, action) => {
  switch (action.type) {
    case FETCH_ITEM_GROUP_REQUESTED:
      return {
        ...state,
        itemGroupsKeyedLoading: {
          ...state.itemGroupsKeyedLoading,
          [action.itemGroupId]: true
        }
      };
    case FETCH_ITEM_GROUP_SUCCESS:
      return {
        ...state,
        itemGroupsKeyed: {
          ...state.itemGroupsKeyed,
          [action.itemGroupId]: action.itemGroup
        },
        itemGroupsKeyedLoading: {
          ...state.itemGroupsKeyedLoading,
          [action.itemGroupId]: false
        }
      };
    case FETCH_ITEM_GROUP_FAILED:
      return {
        ...state,
        itemGroupsKeyedLoading: {
          ...state.itemGroupsKeyedLoading,
          [action.itemGroupId]: false
        }
      };
    default:
      return state;
  }
};

const itemTags = (state = itemTagsInitial, action) => {
  switch (action.type) {
    case ADD_ITEM_TAG_FAILED:
      return {
        ...state,
        addItemTagRequested: false
      };
    case ADD_ITEM_TAG_REQUESTED:
      return {
        ...state,
        addItemTagRequested: true
      };
    case ADD_ITEM_TAG_SUCCESS:
      return {
        ...state,
        addItemTagRequested: false,
        // tagList: map(state.tagList, (item, index) => index === state.tagList.length ? action.payload : item )
        tagList: [...state.tagList, { ...action.payload }]
      };
    case UPDATE_ITEM_TAG_FAILED:
      return {
        ...state,
        updateItemTagRequested: false
      };
    case UPDATE_ITEM_TAG_REQUESTED:
      return {
        ...state,
        updateItemTagRequested: true
      };
    case UPDATE_ITEM_TAG_SUCCESS:
      return {
        ...state,
        updateItemTagRequested: false,
        tagList: map(state.tagList, (item, index) =>
          item.id === action.payload.id ? action.payload : item
        )
      };

    case DELETE_ITEM_TAG_FAILED:
      return {
        ...state,
        updateItemTagRequested: false
      };
    case DELETE_ITEM_TAG_REQUESTED:
      return {
        ...state,
        updateItemTagRequested: true
      };
    case DELETE_ITEM_TAG_SUCCESS:
      return {
        ...state,
        updateItemTagRequested: false,
        tagList: filter(state.tagList, (item, index) => item.id !== action.tagId)
      };
    case ADD_TAGS_TO_ITEMS_REQUESTED:
      return {
        ...state,
        addTagsToItemsRequested: true
      };
    case ADD_TAGS_TO_ITEMS_SUCCESS:
      return {
        ...state,
        addTagsToItemsRequested: false
      };
    case ADD_TAGS_TO_ITEMS_FAILED:
      return {
        ...state,
        addTagsToItemsRequested: false
      };

    case REMOVE_TAGS_FROM_ITEMS_REQUESTED:
      return {
        ...state,
        removeTagsFromItemsRequested: true
      };
    case REMOVE_TAGS_FROM_ITEMS_SUCCESS:
      return {
        ...state,
        removeTagsFromItemsRequested: false
      };
    case REMOVE_TAGS_FROM_ITEMS_FAILED:
      return {
        ...state,
        removeTagsFromItemsRequested: false,
        // tagList: map(state.tagList, (item, index) => index === state.tagList.length ? action.payload : item )
        tagList: [...state.tagList, { ...action.payload }]
      };
    case FETCH_ITEM_TAGS_REQUESTED:
      return {
        ...state,
        tagListLoading: true
      };
    case FETCH_ITEM_TAGS_SUCCESS:
      return {
        ...state,
        tagListLoading: false,
        tagList: action.payload
      };
    case FETCH_ITEM_TAGS_FAILED:
      return {
        ...state,
        tagListLoading: false,
        tagList: action.payload
      };
    default:
      return state;
  }
};

const itemCatalogues = (state = itemCataloguesInitial, action) => {
  switch (action.type) {
    case FETCH_CATALOGUE_ITEMS_REQUEST:
      return {
        ...state,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: true
        }
      };

    case FETCH_CATALOGUE_ITEMS_SUCCESS:
      return {
        ...state,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: false,
          data: map(action.response.data, (item, i) => {
            const index = (action.response.page - 1) * action.response.hitsPerPage + i + 1;
            return { ...item, index: index };
          }),
          pageNo: action.response.page,
          itemIds: action.response.itemIds,
          totalDataCount: action.response.totalDataCount
        }
      };
    case FETCH_CATALOGUE_ITEMS_FAILURE:
      return {
        ...state,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: false
        }
      };
    case SET_SELECTED_CATALOGUE:
      return {
        ...state,
        selectedCatalogue: {
          ...itemCataloguesInitial.selectedCatalogue,
          ...action.catalogue
        }
      };
    case UPDATE_CATALOGUE_SETTINGS_REQUEST:
      return {
        ...state,
        updateCatalogueSettingsRequested: true
      };
    case UPDATE_CATALOGUE_SETTINGS_SUCCESS:
      return {
        ...state,
        selectedCatalogue: {
          ...state.selectedCatalogue,
          settings: { ...action.settings }
        },
        updateCatalogueSettingsRequested: false
      };
    case UPDATE_CATALOGUE_SETTINGS_FAILURE:
      return {
        ...state,
        updateCatalogueSettingsRequested: false
      };

    case REMOVE_CATALOGUE_ITEMS_REQUEST:
    case ADD_CATALOGUE_ITEMS_REQUEST:
      return {
        ...state,
        catalogueItemChangeRequested: true
      };

    case REMOVE_CATALOGUE_ITEMS_SUCCESS:
    case ADD_CATALOGUE_ITEMS_SUCCESS:
      return {
        ...state,
        catalogueItemChangeRequested: false
      };
    case REMOVE_CATALOGUE_ITEMS_FAILURE:
    case ADD_CATALOGUE_ITEMS_FAILURE:
      return {
        ...state,
        catalogueItemChangeRequested: false
      };
    case RENAME_CATALOGUE_ITEMS_REQUEST:
      return {
        ...state,
        catalogueChangeRequested: true
      };

    case UPDATE_CATALOGUE_ITEM_POSITION_REQUESTED:
      return {
        ...state,
        catalogueItemPositionChangeRequested: true,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: true
        }
      };
    case UPDATE_CATALOGUE_ITEM_POSITION_SUCCESS:
      return {
        ...state,
        catalogueItemPositionChangeRequested: false,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: false
        }
      };
    case UPDATE_CATALOGUE_ITEM_POSITION_FAILED:
      return {
        ...state,
        catalogueItemPositionChangeRequested: false,
        selectedCatalogueItemsList: {
          ...state.selectedCatalogueItemsList,
          loading: false
        }
      };

    case RENAME_CATALOGUE_ITEMS_SUCCESS:
      return {
        ...state,
        catalogueChangeRequested: false,
        selectedCatalogue: {
          ...state.selectedCatalogue,
          name: action.name
        }
      };
    case RENAME_CATALOGUE_ITEMS_FAILURE:
      return {
        ...state,
        catalogueChangeRequested: false
      };
    case TOGGLE_EDIT_CATALOGUE_ITEMS_MODE:
      return {
        ...state,
        addItemsToCatalogueMode: action.flag
      };
    case DELETE_CATALOGUE_REQUEST:
      return {
        ...state,
        deleteCatalogueRequested: true
      };

    case DELETE_CATALOGUE_SUCCESS:
      return {
        ...state,
        deleteCatalogueRequested: false
      };
    case DELETE_CATALOGUE_FAILURE:
      return {
        ...state,
        deleteCatalogueRequested: false
      };
    case TOGGLE_CATALOGUE_ACCESS_REQUEST:
      return {
        ...state,
        toggleCatalogueAccessRequested: true
      };

    case TOGGLE_CATALOGUE_ACCESS_SUCCESS:
      return {
        ...state,
        toggleCatalogueAccessRequested: false,
        selectedCatalogue: {
          ...state.selectedCatalogue,
          accessEnabled: action.accessEnabled
        }
      };
    case TOGGLE_CATALOGUE_ACCESS_FAILURE:
      return {
        ...state,
        toggleCatalogueAccessRequested: false
      };
    case CATALOGUE_CREATE_REQUEST:
      return {
        ...state,
        catalogueCreateRequested: true
      };
    case CATALOGUE_CREATE_SUCCESS:
      return {
        ...state,
        createdCatalogueList: action.data,
        catalogueCreateRequested: false
      };
    case CATALOGUE_CREATE_FAILURE:
      return {
        ...state,
        catalogueCreateRequested: false
      };
    case RESET_CATALOGUE_LIST:
      return {
        ...state,
        createdCatalogueList: itemCataloguesInitial.createdCatalogueList,
        catalogueCreateRequested: itemCataloguesInitial.catalogueCreateRequested
      };
    case CHANGE_CATALOGUE_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.key]: action.flag
        }
      };
    case SET_CATALOGUE_SETTINGS:
      return {
        ...state,
        settings: {
          ...itemCataloguesInitial.settings,
          ...action.settings
        }
      };
    case FETCH_LAST_USED_CATALOGUE_SETTINGS_SUCCESS:
      return {
        ...state,
        lastUsedCatalogueSettings: {
          ...itemCataloguesInitial.lastUsedCatalogueSettings,
          ...action.lastUsedSettings
        }
      };
    default:
      return state;
  }
};

const getBranchesForItemOpeingStock = ({ currentCompany, currentCompany: { branches } }) => {
  return (
    branches &&
    branches.map(item => {
      return { refBranchId: item.id, refBranchName: item.name, qty: 0 };
    })
  );
};
const getShowSalesPriceValue = ({
  items: {
    app: { showSalesPriceValue }
  }
}) => showSalesPriceValue;
const getItemGroupSearchText = ({ items: { _itemGroupSearchText } }) => _itemGroupSearchText;
const getSelectedItemGroup = ({ items: { _selectedItemGroup } }) => _selectedItemGroup;
const getShowItemGroup = ({
  items: {
    app: { onShowItemGroup }
  }
}) => onShowItemGroup;
const getShowItemSalesPrice = ({
  items: {
    app: { showItemSalesPrice }
  }
}) => showItemSalesPrice;
const getAllItemGroups = ({ items: { itemGroups } }) => itemGroups;
// const getAllItemCollections = ({ items: { itemCollections } }) => itemCollections;
const getItemPayloadDetails = ({ items: { payload: itemPayload } }) => itemPayload;
const getItemError = ({ items: { itemError } }) => itemError;
const getTempItemData = (state = null, payload) => {
  if (payload.type === TEMP_ITEM_DATA) {
    return payload.payload;
  }
  return state;
};

const getItemPersistData = ({ items: { persistData } }) => persistData;

const getParties = ({
  contacts: {
    partyListData: { partyList }
  }
}) => partyList;

//selectors
const getItemsNames = ({ items: { items } }) => {
  return items.map(item => item.name);
};

const getItemsNamesFromRefVoucher = ({
  vouchers: {
    _selectedVoucherByNo: { itemList }
  }
}) => {
  return itemList;
};

const getItems = ({ items: { items } }) => items;
const getOnShowAddItems = ({
  items: {
    app: { onShowAddItems }
  }
}) => onShowAddItems;

const getItemGroupAndProperty = ({ items: { _selectedItemGroup, collectionType } }) => {
  return { _selectedItemGroup, collectionType };
};

const getItemsByGroup = ({ items: { itemsByGroup } }) => itemsByGroup;
const getGroupedItems = ({
  items: {
    [GROUPED_ITEMS_TABLE]: { loading, data, pageNo, totalDataCount }
  }
}) => {
  return {
    loading,
    data,
    pageNo,
    totalDataCount
  };
};
const getItemsCountByGroup = (state, storeKey) => {
  const {
    items: { itemsCountByGroup, [storeKey]: { totalDataCount } = {} }
  } = state;

  return storeKey ? totalDataCount : itemsCountByGroup;
};
const getItemsByGroupPageNo = (state, storeKey) => {
  const {
    items: { itemsByGroupPageNo, [storeKey]: { pageNo } = {} }
  } = state;

  return storeKey ? pageNo : itemsByGroupPageNo;
};

const getItemUnits = ({ items: { itemUnits } }) => {
  return itemUnits.map(item => {
    return { ...item, label: item.value };
  });
};

const getGroupedItemUnits = ({
  items: {
    itemGroupedUnits,
    payload: { unit }
  }
}) => {
  return itemGroupedUnits.map(item => {
    return { ...item, label: unit ? `${unit} per ${item.value}` : item.value };
  });
};

const getTabViewItemGroup = ({ items: { tabViewItemGroup } }) => tabViewItemGroup;

const getErrorStatus = ({
  items: {
    itemServerError,
    app: { showAlertDialog }
  }
}) => {
  return {
    itemServerError,
    showAlertDialog
  };
};

export {
  items,
  itemAttributes,
  itemTags,
  itemCatalogues,
  getTempItemData,
  getItemsNames,
  getItems,
  getOnShowAddItems,
  getShowItemGroup,
  getSelectedItemGroup,
  getAllItemGroups,
  getItemPayloadDetails,
  getShowItemSalesPrice,
  getItemGroupSearchText,
  getShowSalesPriceValue,
  getBranchesForItemOpeingStock,
  getItemError,
  getParties,
  getItemGroupAndProperty,
  getItemsByGroup,
  getTabViewItemGroup,
  getErrorStatus,
  // getAllItemCollections,
  getItemsNamesFromRefVoucher,
  getItemPersistData,
  getItemUnits,
  getItemsCountByGroup,
  getItemsByGroupPageNo,
  getGroupedItems,
  getGroupedItemUnits,
  itemGroups
};
