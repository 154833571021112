import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  justify-content: space-between;
`;

export const Grey16 = styled.span`
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 400;
  text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const Grey16Med = styled.span`
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 500;
`;

export const Grey14 = styled.span`
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Green13Med = styled.span`
  color: #219653;
  font-size: 0.8125rem;
  font-weight: 500;
`;

export const ShortcutText = styled.span`
  color: #828282;
  font-size: 0.625rem;
  font-weight: 400;
  position: absolute;
  text-transform: none;
  cursor: auto;
  font-style: italic;
`;

export const SubmitButton = styled.button`
  color: ${p => (p.isDisable ? '#95989A' : '#2f80ed')};
  padding: 0.5rem;
  &: hover {
    cursor: ${p => (p.isDisable ? 'not-allowed' : 'pointer')};
  }
  background: transparent;
  border: none;
`;

export const ThumbnailLine = styled.div`
  color: #707070;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  justify-content: ${props => (props.align ? props.align : 'center')};
  padding: 5px 0;
`;

export const ThumbnailLabel = styled.span`
  font-weight: 600;
`;
export const ThumbnailLineSpan = styled.span``;

export const SelectedItemContainer = styled.div`
  display: flex;
  padding: 20px 30px 0px 30px;
  overflow-y: scroll;
  height: inherit;
  margin-left: 30px;
  width: calc(70% - 30px);
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  ${props =>
    props.type === 'empty'
      ? `
    align-items: center;
    justify-content: center;
  `
      : `
    align-items: center;
    justify-content: flex-start;
  `};
`;

export const CancelGrey14 = styled.div`
  color: #707070;
  font-size: 0.875rem;
  font-weight: 400;
  cursor: 'pointer';
`;

export const Grey14Italic = styled.div`
  color: #707070;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: italic;
`;

export const Blue14 = styled.div`
  color: #428bca;
  font-size: 0.875rem;
  font-weight: 400;
  cursor: pointer;
`;

export const Blue13Med = styled.div`
  color: #2f80ed;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
`;

export const Blue14Span = styled.span`
  color: #2f80ed;
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Black20 = styled.div`
  color: #000000;
  font-size: 1.25rem;
  font-weight: 400;
`;

export const Blue14Link = styled(props => <Link {...props} />)`
  color: #4285f4;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Grey14Med = styled.div`
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const EditBtn = styled.span`
  color: #428bca;
  font-size: 0.875rem;
  padding-right: 2rem;
  cursor: pointer;
`;

export const BlueBorderedActionButton = styled.span`
  color: #428bca;
  font-size: 0.875rem;
  cursor: pointer;
  border: 1px solid #428bca;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 35px;
`;

export const Blue14LinkItalic = styled.span`
  color: #428bca;
  font-size: 0.875rem;
  padding-right: 2rem;
  cursor: pointer;
  font-style: italic;
`;

export const DeleteBtn = styled.span`
  color: #d82121;
  font-size: 0.875rem;
  padding-right: 2rem;
  cursor: pointer;
`;

export const ErrorText14 = styled.span`
  color: #d82121;
  font-size: 0.875rem;
  padding-right: 2rem;
`;

export const SubSectionHeader = styled.span`
  color: #707070;
  font-size: 1rem;
  padding-left: 0.9375rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`;

export const SubSectionItalic = styled.span`
  color: #000000;
  font-size: 1rem;
  font-style: italic;
`;

export const Black16 = styled.span`
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
  text-transform: ${props => (props.transform ? props.transform : 'none')};
`;

export const Black16Med = styled.span`
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
`;

export const Black14 = styled.span`
  color: #000000;
  font-size: 0.875rem;
  margin-right: 1.125rem;
`;

export const Black14Link = styled(props => <Link {...props} />)`
  color: #000000;
  font-size: 0.875rem;
  margin-right: 1.125rem;
  cursor: pointer;
`;

export const Black14Med = styled.span`
  color: #000000;
  font-size: 0.875rem;
  margin-right: 1.125rem;
  font-weight: 500;
`;

export const Black14Italic = styled.span`
  color: #000000;
  font-size: 0.875rem;
  font-style: italic;
`;

export const Black20Med = styled.span`
  color: #000000;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const Black13 = styled.span`
  color: #000;
  font-size: 0.8125rem;
`;

export const Black12Italic = styled.span`
  color: #000;
  font-size: 0.8rem;
  font-style: italic;
`;

export const Grey12Italic = styled.span`
  color: #4f4f4f;
  font-size: 0.75rem;
  font-style: italic;
`;

export const Grey12Med = styled.span`
  color: #4f4f4f;
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Grey12 = styled.span`
  color: #4f4f4f;
  font-size: 0.75rem;
`;
export const Grey8 = styled.span`
  color: #4f4f4f;
  font-size: 0.5rem;
`;
export const Grey10 = styled.span`
  color: #4f4f4f;
  font-size: 0.625rem;
`;

export const Grey11 = styled.span`
  color: #4f4f4f;
  font-size: 0.6875rem;
`;

export const Grey14MedSpan = styled.span`
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 500;
`;
export const Grey13Med = styled.span`
  color: #4f4f4f;
  font-size: 0.8125rem;
  font-weight: 500;
`;

export const Grey13Light = styled.span`
  color: #828282;
  font-size: 0.8125rem;
`;

export const Blue10 = styled.span`
  color: #2f80ed;
  font-size: 0.625rem;
`;

export const Grey12Light = styled.span`
  color: #828282;
  font-size: 0.75rem;
`;

export const Grey13 = styled.span`
  color: #4f4f4f;
  font-size: 0.8125rem;
`;

export const White12 = styled.span`
  color: #fff;
  font-size: 0.75rem;
`;

export const White13 = styled.span`
  color: #fff;
  font-size: 0.8125rem;
`;
export const White14Med = styled.span`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
`;
export const White16Med = styled.span`
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
`;

export const White20Med = styled.span`
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const White12Med = styled.span`
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Green14 = styled.span`
  color: #219653;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  font-size: 0.875rem;
`;

export const Red14 = styled.span`
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  font-size: 0.875rem;
  color: rgba(232, 36, 36, 1);
`;

export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.25rem 0;
`;

export const RowDivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RedHighlight = styled.span`
  border: 0.0625rem solid #b91717;
  border-radius: 0.1875rem;
  color: #b91717;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0rem 0.625rem;
  line-height: 0.875rem;
  height: max-content;
`;

export const BlueHighlight = styled.span`
  border: 0.0625rem solid rgba(66, 133, 244, 1);
  border-radius: 0.1875rem;
  color: #4285f4;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0rem 0.625rem;
  line-height: 0.875rem;
  height: max-content;
  white-space: nowrap;
`;

export const GreyHighlight = styled.span`
  border: 0.0625rem solid #828282;
  border-radius: 0.1875rem;
  color: #828282;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0rem 0.625rem;
  line-height: 0.875rem;
  height: max-content;
  white-space: nowrap;
`;

export const GreenHighlight = styled.div`
  border: 0.0625rem solid #50d355;
  border-radius: 0.1875rem;
  color: #50d355;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0rem 0.625rem;
`;

export const SolidColorSpan = styled.span`
  border: 0.0625rem solid rgba(66, 133, 244, 1);
  border-radius: 0.1875rem;
  color: #4285f4;
  font-style: italic;
  font-size: 0.75rem;
  padding: 0rem 0.625rem;
  line-height: 0.875rem;
`;

export const Label = styled.label`
  display: table;
  margin: auto;
  color: #428bca;
  padding-right: 0.625rem;
`;

export const BankHeader = styled.div`
  color: #999999;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const Spacer = styled.span`
  flex: 1;
`;

export const TooltipTableContainer = styled.span`
  display: flex;
  min-width: 12rem;
  justify-content: space-between;
`;

export const MultiLineEllipseContainer = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // max-width: 18rem;
  max-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2rem;
`;

export const SectionDiv = styled.div`
  border: 0.0625rem solid rgba(112, 112, 112, 0.3);
  border-radius: 0.1875rem;
  margin-bottom: 1.25rem;
`;

export const DetailDiv = styled.div`
  margin: 0.8125rem 1.0625rem;
  align-items: center;
  display: flex;
`;

export const Button = styled.button`
  height: 30px;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 0.3125rem 20px;
  text-align: center;
  align-items: center;
  border-radius: ${p => (p.borderRadius ? p.borderRadius : '5px')};
  width: ${p => (p.width ? p.width : '100%')};
  text-decoration: none;
  justify-content: center;
  background-color: ${p =>
    p.disabled ? '#b9b9b9' : p.backgroundColor ? p.backgroundColor : '#27d466'};
  border: 1px solid
    ${p => (p.disabled ? '#b9b9b9' : p.backgroundColor ? p.backgroundColor : '#27d466')};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  ${p =>
    p.showFocus
      ? ''
      : `&:focus {
    outline: none;
  }`}
`;

export const ButtonLink = styled(props => <Link {...props} />)`
  height: 30px;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 0 20px;
  align-self: center;
  text-align: center;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  justify-content: center;
  background-color: #27d466;
  border: 1px solid #27d466;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonGreen = styled.button`
  height: 30px;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 20px;
  text-align: center;
  align-items: center;
  border-radius: ${p => (p.borderRadius ? p.borderRadius : '100px')};
  text-decoration: none;
  justify-content: center;
  background-color: #27d466;
  border: 1px solid #27d466;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  &:focus {
    outline: none;
  }
`;

export const Column = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
`;

export const containerDiv = {
  width: '44rem',
  marginTop: '2.8125rem',
  position: 'relative'
};

export const ContainerDiv = styled.div`
  width: 44rem;
  margin-top: 2.8125rem;
  position: relative;
  overflow-y: ${props => (props.hideOverflow ? 'hidden' : 'initial')};
`;

export const Icon = styled.img`
  background-color: transparent;
  width: ${props => (props.imgWidth ? props.imgWidth : '1.5rem')};
  height: ${props => (props.imgHeight ? props.imgHeight : '1.5rem')};
`;

export const IconButton = styled.img`
  background-color: transparent;
  cursor: ${props => (props.selected ? 'auto ' : 'pointer')};
  width: ${props => (props.imgWidth ? props.imgWidth : '1.5rem')};
  height: ${props => (props.imgHeight ? props.imgHeight : '1.5rem')};
`;

export const ActionButton = styled.button`
  height: 2.5rem;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.3125rem 2rem;
  margin-right: 1rem;
  border-radius: 100px;
  background-color: ${props => (props.disabled ? '#b9b9b9' : '#27d466')};
  border: 1px solid ${props => (props.disabled ? '#b9b9b9' : '#27d466')};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  font-weight: 600;
  align-items: center;
`;

export const Title = styled.div`
  color: #aaaaaa;
  cursor: default;
  font-size: 18px;
  font-family: 'Roboto';
`;

export const BlueLink12 = styled(props => <Link {...props} />)`
  cursor: pointer;
  font-size: 0.75rem;
  color: #4285f4;
`;
export const BlueLink13 = styled(props => <Link {...props} />)`
  cursor: pointer;
  font-size: 0.8125rem;
  color: #4285f4;
`;

export const BlueA13 = styled.a`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  font-size: 0.8125rem;
  color: ${props => (props.disabled ? '#bdbdbd' : '#4285f4')};
`;

export const BlueLink14 = styled(props => <Link {...props} />)`
  cursor: pointer;
  font-size: 0.875rem;
  color: #4285f4;
`;
export const Blue11 = styled.span`
  cursor: pointer;
  font-size: 0.6875rem;
  color: #4285f4;
`;
export const Blue12 = styled.span`
  cursor: pointer;
  font-size: 0.75rem;
  color: #4285f4;
`;

export const Blue12Italic = styled.span`
  cursor: pointer;
  font-size: 0.75rem;
  color: #4285f4;
  font-style: italic;
`;

export const Red12 = styled.span`
  cursor: ${props => (props.button ? 'default' : 'pointer')};
  font-size: 0.75rem;
  color: ${props => (props.disabled ? '#828282' : '#EB5757')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const Red10 = styled.span`
  font-size: 0.625rem;
  color: ${props => (props.disabled ? '#828282' : '#EB5757')};
`;

export const TickIcon = styled.img`
  background-color: transparent;
  width: 1rem;
  height: 1rem;
`;

export const BackBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 1;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
  border: 2px solid rgba(224, 224, 224, 1);
  border-radius: 3px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid #707070;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`;

export const DrawerContent = styled.div`
  display: flex;
  padding: 0rem 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CreditDebitToggle = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 0.875rem;
  height: 1.6875rem;
  padding: 0 2rem;

  border-radius: 15px;
  border: 0.0625rem solid rgb(158, 158, 158);

  cursor: ${props => (props.active ? 'default' : 'pointer')};
  background-color: ${props => (props.active ? 'rgb(158, 158, 158)' : 'none')};

  color: ${props => (props.active ? '#FFFFFF' : '#868686')};
  font-weight: ${props => (props.active ? '400' : '400')};
`;

export const SelectContactFormContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  & > :nth-child(n) {
    flex: 1;
    padding-bottom: 1.25rem;
    max-width: 100%;
  }
`;

export const GSTBillingFormContainer = styled.div`
  // padding: 0.875rem 3.75rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > :nth-child(n) {
    flex: 1 33%;
    max-width: 33%;
    padding-bottom: 1.25rem;
  }
  & > :nth-child(5) {
    flex: 3 100%;
    max-width: 100%;
  }
  // & > :nth-child(2) {
  //   max-width: 32%;
  // }
  // & > :nth-child(3) {
  //   max-width: 32%;
  // }
  & > :nth-child(1) {
    flex: 3 100%;
    max-width: 100%;
    display: flex;
  }
`;

export const ForgotPassword = styled(props => <Link {...props} />)`
  color: #27d466;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  text-align: right;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ReportTableContainer = styled.div`
  position: relative;
  margin: 1rem;
`;

export const DuplicateItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const DuplicateItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  justify-content: space-between;
  height: 3rem;
  margin: 1rem 2rem 0rem 2rem;
`;

export const DuplicateItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const TableOptions = styled.div`
  display: flex;
  padding-left: 3rem;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 1rem 0;
  & > div {
    margin-right: 1rem;
  }
`;

export const PrimaryButton = styled.button`
  height: 28px;
  display: flex;
  color: ${p => (p.disabled ? 'grey' : '#428bca')};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  padding: 5px 20px;
  text-align: center;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  justify-content: center;
  border: ${p => (p.disabled ? '1px solid #e4e4e4' : '1px solid #428bca')};
  background-color: ${p => (p.disabled ? '#e4e4e4' : '#ffffff')};
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  &:focus {
    outline: none;
  }
`;

export const FormHeaderContainer = styled.div`
  background-color: #f6f6f6;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const FormRow = styled.div`
  ${p =>
    p.type === 'noPadding'
      ? `
    width: 100%;
    padding: 0px;
    display: flex;
    flex-shrink: 0;
    margin-top: 10px;
  `
      : `
    height: 65px;
    display: flex;
    flex-shrink: 0;
    padding: 0px 1.25rem;
    width: calc(100% - 40px);
 `};
`;

export const ToggleFilterButton = ({
  id = 'toggle-filter-button',
  filterFlag,
  onClick,
  style,
  showLabel,
  counterFilter
}) => {
  return (
    <Tooltip placement="top" title={filterFlag ? 'Hide Filter' : 'Show Filter'}>
      <PrimaryButton
        id={id}
        style={{ background: !filterFlag ? 'none' : '#2f80ed', padding: '9px', ...style }}
        onClick={e => {
          onClick(!filterFlag, e);
        }}
        className="filter-button"
      >
        {counterFilter > 0 && <span className="applied-filter">{counterFilter}</span>}
        <span style={{ color: !filterFlag ? '#2f80ed' : 'white' }}>
          <i className="fa fa-filter" aria-hidden="true" />{' '}
          {showLabel && <span style={{ marginLeft: '3px' }}> Filters </span>}
        </span>
      </PrimaryButton>
    </Tooltip>
  );
};

export const ItemAttributeList = styled.div`
  border-radius: 0.4rem;
  border: 1px solid #000;
  margin: 1rem 0 1rem 0;
  height: 20rem;
  width: inherit;
`;

export const ItemAttributeListTitle = styled.div`
  display: flex;
  height: 2rem;
  align-items: center;
  padding: 0 1rem;
`;

export const PrimaryLink = styled(props => <Link {...props} />)`
  height: 1rem;
  display: flex;
  color: #2f80ed;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 20px;
  text-align: center;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  justify-content: center;
  border: 1px solid #2f80ed;
  background-color: #ffffff;
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  &:focus {
    outline: none;
  }
`;

export const ProfileImg = styled.img`
  background-color: transparent;
  width: ${props => (props.imgWidth ? props.imgWidth : '1.5rem')};
  height: ${props => (props.imgHeight ? props.imgHeight : '1.5rem')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};
`;

export const ExternalLink = styled.a`
  height: 1rem;
  display: flex;
  color: #2f80ed;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 20px;
  text-align: center;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  justify-content: center;
  border: 1px solid #2f80ed;
  background-color: #ffffff;
  font-weight: ${p => (p.fontWeight ? p.fontWeight : '400')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
  &:focus {
    outline: none;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  // margin-top: 15px;
  border-top: 1px solid #dbdbdb;
  padding: 10px 1.5rem;
`;

export const FooterLabel = styled.span`
  font-size: 0.8125rem;
`;

export const FooterValue = styled.span`
  font-size: 0.8125rem;
  text-align: right;
`;

export const ReportSectionHeader = styled.div`
  font-size: 18px;
  text-align: center;
  height: 48px;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #4f4f4f;
  font-weight: 500;
  padding-bottom: 12px;
`;

export const ReportContainer = styled.div`
  height: 75vh;
  padding-top: 5px;
  margin: 0 1rem;
  @media screen and (min-height: 500px) {
    height: calc(54vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 600px) {
    height: calc(59vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 650px) {
    height: calc(62vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 700px) {
    height: calc(64vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 740px) {
    height: calc(67vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 850px) {
    height: calc(70vh - ${p => p.heightOffset}px);
  }

  @media screen and (min-height: 900px) {
    height: calc(72vh - ${p => p.heightOffset}px);
  }
`;

export const Dot = styled.span`
  display: flex;
  padding: 0.25rem;
  align-self: baseline;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-top: 0.4rem;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  margin-left: 0.5rem;
  border-color: transparent;
  background-color: #1976d2;
`;

const Pulse = keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
    background-color: rgba(0,0,0, 0.2);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    background-color: rgba(0,0,0, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    background-color: rgba(0,0,0, 0.2);
  }
`;
const pulseAnimation = props =>
  props.pulse
    ? css`
        ${Pulse} 1s infinite alternate;
      `
    : 'none';
export const PulseBox = styled.span`
  animation: ${pulseAnimation};
  border-radius: 3px;
`;

export let styles = {
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  root: {
    padding: '1rem 1.875rem',
    display: 'flex',
    fontSize: '0.875rem',
    color: '#000000',
    borderBottom: '0.0625rem solid #000000',
    alignItems: 'center'
  },
  catalogueDialogRoot: {
    padding: '1rem 1.875rem',
    fontSize: '0.875rem',
    color: '#000000',
    borderBottom: '0.0625rem solid #000000'
  },
  paper: {
    width: '100%'
  },
  dialogHeader: {
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f9f9fb',
    fontFamily: 'Roboto',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e7e7e8',
    padding: '0px 20px'
  },
  textField: {
    width: '12.5rem'
  },
  btnFocus: {
    backgroundColor: '#c8cce6'
  },
  btnNewSubmit: {
    borderRadius: 18,
    color: '#219653',
    border: '1px solid #219653',
    fontSize: '0.875rem',
    minWidth: '6rem',
    // minHeight: 30,
    padding: '3px'
    // '&:hover': {
    //   backgroundColor: '#26D367'
    // }
  },
  btnNewDel: {
    background: '#EB5757',
    borderRadius: 18,
    color: '#ffffff',
    border: 0,
    fontSize: '0.875rem',
    minHeight: 30,
    padding: '0 1.875rem',
    '&:hover': {
      backgroundColor: '#EB5757'
    }
  },
  btnNewReg: {
    borderRadius: 18,
    color: '#2F80ED',
    border: '1px solid #2F80ED',
    fontSize: '0.875rem',
    minWidth: '8rem'
  },
  btnNewDelOutlined: {
    borderRadius: 18,
    color: '#EB5757',
    border: '1px solid #EB5757',
    fontSize: '0.875rem',
    minWidth: '8rem'
  },
  btnSquGrey: {
    color: '#828282',
    fontSize: '0.875rem'
  },
  btnSquGreen: {
    color: '#27AE60',
    fontSize: '0.875rem'
  },
  btnSquBlue: {
    color: '#2F80ED',
    fontSize: '0.875rem'
  },
  btnSquRed: {
    color: '#EB5757',
    fontSize: '0.875rem'
  },
  btn: {
    background: '#27ae60',
    borderRadius: 18,
    border: 0,
    color: '#FFFFFF',
    fontSize: '0.875rem',
    minHeight: 30,
    padding: '0 1.875rem',
    '&:hover': {
      backgroundColor: '#27ae60'
    }
  },
  btnSquareBordersGreen: {
    marginRight: '10px',
    border: '1px solid #27AE60',
    borderRadius: '2px',
    padding: '0 0.5rem',
    color: '#27AE60',
    textTransform: 'none',
    fontWeight: 400
  },
  btnSquareBorders: {
    marginRight: '10px',
    border: '1px solid #2F80ED',
    borderRadius: '2px',
    padding: '0 0.5rem',
    color: '#2F80ED',
    textTransform: 'none',
    fontWeight: 400
  },
  dialogContent: {
    padding: '0 1.875rem',
    '& > :nth-child(n)': {
      paddingBottom: '1.25rem'
    }
  },
  displayDiv: {
    display: 'flex'
  },
  content: {
    padding: '1.875rem 3.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    '& > :nth-child(n+2)': {
      flex: '1 33%',
      maxWidth: '33%'
    },
    '& > :nth-child(1)': {
      flex: '3 100%'
    }
  },
  dialogDiv: {
    maxWidth: '50rem'
  },
  shareDiv: {
    width: '55rem'
  },
  dialogDivVariantTable: {
    width: '80vw',
    height: '80vh'
  },
  action: {
    margin: '0rem 3.75rem 1.875rem 3.75rem'
  },
  selectMenu: {
    fontSize: '0.875rem',
    flexDirection: 'row'
  },
  selectRoot: {
    width: '12.5rem'
  },
  chipInputRoot: {
    backgroundColor: 'rgba(134, 134, 134, 0.16)',
    height: 'auto',
    color: '#868686'
    // paddingTop: '2.8125rem'
  },
  chipInputInvalid: {
    // backgroundColor: 'rgba(134, 134, 134, 0.16)',
    height: 'auto',
    color: '#000000'
    // paddingTop: '2.8125rem'
  },
  chipInputInput: {
    textAlign: 'left',
    padding: '0rem',
    fontSize: '0.75rem'
  },
  chip: {
    fontSize: '0.75rem',
    color: '#868686',
    height: '1rem',
    backgroundColor: 'rgba(134, 134, 134, 0.16)'
  },
  chipContainer: {
    minHeight: '1rem',
    ':before': {
      backgroundColor: 'red'
    }
  },
  lablePrimary: {
    fontSize: '0.875rem'
  },
  lablePrimaryError: {
    fontSize: '0.875rem',
    color: 'rgba(232, 36, 36, 1)'
  },
  lableSecondary: {
    fontSize: '0.75rem',
    color: '#868686'
  },
  datePadding: {
    padding: '0rem 1.25rem'
  },
  // radio: {
  //   fontSize: '0.625rem'
  // },
  radio: {
    fontSize: '0.625rem',
    color: '#428BCA',
    '&:checked': {
      color: '#428BCA'
    },
    '&:focus-within': {
      backgroundColor: 'rgba(245, 0, 87, 0.4)'
    },
    height: '0.9375rem',
    width: '0.9375rem'
  },
  voucherRadioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: '0.785rem 2.785rem 0 2.785rem',

    '& .MuiSvgIcon-root': {
      fontSize: '1.175rem'
    }
  },
  voucherRadio: {
    fontSize: '0.815rem'
  },
  switchBase: {
    // '&:checked': {
    //   color: '#428BCA'
    // },
    '&:focus-within': {
      backgroundColor: 'rgba(245, 0, 87, 0.4)'
    }
  },
  label: {
    fontSize: '0.875rem'
  },
  formLabel: {
    fontSize: '0.75rem'
  },
  group: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  radioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    textTransform: 'capitalize'
  },
  inputFont: {
    fontSize: '12px',
    flex: '1 auto'
  },
  table: {
    // minWidth: '55rem'
  },
  roleTable: {
    width: '46rem'
  },
  tableHeadEnd: {
    textAlign: 'right'
  },
  tableReconCellVoucher: {
    fontSize: '0.875rem',
    paddingLeft: '0rem',
    color: '#428BCA',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    cursor: 'pointer'
  },
  tableReconCell: {
    fontSize: '0.875rem',
    paddingLeft: '0rem',
    color: '#000000'
  },
  TableRoleCellFirst: {
    fontSize: '0.875rem',
    padding: '0 0 0 1rem',
    color: '#000000'
  },
  tableRoleCell: {
    color: '#000000',
    padding: '0rem 1rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    width: '70px'
  },
  tableRoleRow: {
    height: '2.5rem',
    '& > :nth-child(n)': {
      border: 'none',
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    '& > :nth-child(7)': {
      borderRight: 'none'
    }
  },
  tabsRoot: {
    borderBottom: '0.0625rem solid #e8e8e8',
    backgroundColor: 'rgba(174,174,174,0.06)',
    minHeight: '1.875rem'
  },
  tabsIndicator: {
    backgroundColor: '#428BCA'
  },
  tabRoot: {
    textTransform: 'capitalize',
    minWidth: 72,
    padding: '3px 12px',
    // maxWidth: 120,
    minHeight: '1.875rem',
    color: '#868686',
    fontFamily: ['Roboto'].join(','),
    fontSize: '0.8125rem',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    },
    '&:tabSelected': {
      color: '#428BCA'
    },
    '&:focus': {
      color: '#40a9ff'
    }
  },
  formControlRoles: {
    width: '10rem',
    alignSelf: 'center'
  },
  searchFormControl: {
    width: '40rem'
  },
  searchLine: {
    width: '100%',
    fontSize: '0.875rem',
    borderBottom: '1px solid #707070',
    '&::placeholder': {
      color: '#000'
    }
  },
  newMemberForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  radioPrimary: {
    color: '#428BCA'
  },
  itemDiscount: {
    fontSize: '0.875rem',
    textAlign: 'center'
  },
  itemTableHeaderRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    height: '1.875rem',
    '& > :last-child': {
      paddingRight: '5rem'
    },
    '& > :nth-child(1)': {
      paddingLeft: '5rem'
    },
    '& > :nth-child(n+2)': {
      textAlign: 'center'
    }
  },
  itemTableCell: {
    fontSize: '0.875rem',
    color: '#707070',
    fontWeight: 400,
    border: 'none'
  },
  itemTableRow: {
    '& > :nth-child(n)': {
      alignItems: 'center'
    },
    '& > :nth-child(1)': {
      paddingLeft: '5rem'
    },
    '& > :last-child': {
      paddingRight: '5rem'
    }
  },
  billingTypeSelect: {
    fontSize: '0.875rem'
  },
  dialogDivVoucherGeneral: {
    // maxWidth: '90%',
    // minWidth: '90%',
    width: '90%',
    overflowX: 'hidden'
  },
  dialogDivAddItem: {
    // maxWidth: '90%',
    // minWidth: '90%',
    width: '65%'
  },
  tableCell: {
    fontSize: '0.875rem',
    color: '#868686',
    border: 'none',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    cursor: 'pointer',
    textAlign: 'center'
  },
  searchFilter: {
    padding: '1rem 1.5rem 0 1.5rem'
  },
  colorPrimary: '',
  chipPrimary: {
    color: '#4285f4',
    borderColor: '#4285f4'
  },
  itemAttributesConfig: {
    padding: '0rem 0 0 2rem',
    width: '96%',
    display: 'flex',
    flexDirection: 'inherit',
    justifyContent: 'space-between'
  },
  itemAttributesConfigForm: {
    flexDirection: 'column',
    height: '18rem',
    width: 'inherit',
    padding: '0 1rem'
  },
  contentShare: {
    padding: '0.5rem 1.875rem',
    display: 'flex',
    flexWrap: 'wrap',
    '& > :nth-last-child(1)': {
      flex: '3 100%'
    }
  },
  barcodeDialogContent: {
    padding: '0.5rem 1.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemNewUIMsgTitle: {
    fontSize: '22px',
    backgroundColor: '#27AE60',
    fontWeight: '500',
    color: '#fff',
    textAlign: 'center',
    height: '78px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  barcodeDialogContainer: {
    width: '30rem',
    height: '10rem'
  },
  itemNewUIMsgContent: {
    padding: '0.5rem 1.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  itemNewUIMsgContainer: {
    width: '60rem',
    minHeight: '40rem'
  },
  textFieldCustom: {
    '& label': {
      color: 'rgba(128, 128, 128, 1)'
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgb(224,224,224)'
    }
  },
  autoGenLabel: {
    fontSize: '11px'
  },
  dropdownSelect: {
    fontSize: '0.8125rem'
    // padding: '0 1.5rem 0 0'
  },
  // dropdownSelectRoot: {
  //   height: '1.5rem'
  // },
  dropdownSelectOptions: {
    fontSize: '0.8125rem',
    padding: '0 0.5rem'
  },
  reportsHomeCard: {
    padding: '12px'
  },
  reportsHomeAvatar: {
    width: '60px',
    height: '48px',
    borderRadius: 0
  },
  reportsHomeAvatarContainer: {
    width: '60px',
    height: '48px',
    background: '#F2F2F2',
    borderRadius: '5px',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  reportsHomeCardText: {
    fontSize: '1rem',
    paddingLeft: '1.5rem',
    color: '#828282'
  },
  expandedPanelSummary: {
    margin: '0 -6px 0 0 !important'
  },
  summaryTableHeader: {
    backgroundColor: 'rgb(242, 242, 242)',
    height: '2rem'
  },
  summaryTableHeaderCell: {
    padding: '0.5rem 2rem',
    color: 'rgb(79, 79, 79)'
  },
  summaryTableCell: {
    fontSize: '0.75rem',
    padding: '0.5rem 2rem',
    color: 'rgb(130, 130, 130)'
  },
  summaryTableFooterCell: {
    fontSize: '0.75rem',
    padding: '0.5rem 2rem',
    color: '#4F4F4F'
  },
  muiChip: {
    borderRadius: '0.25rem',
    height: '1.5rem'
  },
  muiTableChip: {
    color: '#fff',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    height: '1rem',
    margin: '0 3px',
    maxWidth: '40%'
  },
  muiHoverChip: {
    color: '#fff',
    borderRadius: '0.25rem',
    fontSize: '0.75rem',
    height: '1rem',
    margin: '3px',
    fontWeight: 400
  },
  partySelectionDropdown: {
    borderRadius: '5px 0 0 5px',
    border: '0.5px solid #BDBDBD',
    backgroundColor: '#F6F6F6',
    paddingLeft: '0.5rem'
  },
  itemFormContainer: {
    padding: 0
  },
  thumbnailCard: {
    textAlign: 'center',
    position: 'relative'
  },
  thumbnailCheckbox: {
    position: 'absolute',
    right: 0
  },
  icon: {
    borderRadius: 2,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#2F80ED',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },
  outlinedInput: {
    height: '17px',
    fontSize: '0.875rem',
    padding: '8px 14px',
    paddingBottom: '7px',
    border: '1px solid #bdbdbd',
    borderRadius: 5,
    borderLeft: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  filterModal: {
    position: 'absolute',
    bottom: 0,
    left: '4rem',
    right: '4rem',
    maxWidth: '800px',
    margin: '0 auto'
  },
  filterDone: {
    width: '100%',
    backgroundColor: 'var(--secondary-color)',
    color: '#ffffff',
    padding: '10px 0',
    borderRadius: 0,
    fontSize: 16,
    '&:hover': {
      background: 'var(--secondary-color)'
    }
  },
  filterResetButton: {
    color: 'var(--primary-color)',
    display: 'inline-block',
    fontSize: 13,
    width: 150,
    marginLeft: 'auto'
  },
  quickItemCreate: {
    width: '100%'
  },
  quickItemCreateSetting: {
    width: '100%',
    zIndex: '1600 !important'
  }
};
