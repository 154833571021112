import { navigate } from './nav';
import mixpanel from 'mixpanel-browser';
import {
  BUSINESS_ACCOUNTS,
  ERROR,
  TRANSPORTER,
  VOUCHER_PARTY_PICKER,
  PARTY_PROFILE,
  EDIT_TRANSPORTER_TAB_PARTY_PICKER,
  ADD_TRANSPORTER_TAB_PARTY_PICKER,
  PARTY_SELECT,
  PARTY_STATEMENT,
  CONTACTS,
  ASCENDING,
  DESCENDING,
  TABLE_ASCENDING,
  SELECTIVE,
  ITEM_FORM,
  UNRECONCILED_VOUCHER_STATEMENT,
  UNRECONCILED_VOUCHERS,
  UNRECONCILED,
  INPROGRESS,
  RECONCILED,
  RECONCILED_SELCTED_GROUP_MODAL,
  RECONCILE_MODAL_PAGE_SIZE,
  PARTY_LIST_DATA,
  ADD_SALES_PERSON_MODAL,
  AJ,
  ALL_UNRECONCILED,
  VOUCHER_TYPE_ALL
} from '../constants';
import moment from 'moment';
import { serverError } from '../api/server';
import { updateSalesPersonDetails } from './vouchers';
import {
  CONTACT_LIST_FAILED,
  CHECK_FOR_NAME_FAILED,
  CHECK_FOR_NAME_SUCCESS,
  CONTACT_LIST_REQUESTED,
  ADD_CONTACT_DATA_FAILED,
  ADD_CONTACT_DATA_REQUEST,
  ADD_CONTACT_DATA_SUCCESSS,
  UPDATE_CONTACT_NAME_FAILED,
  UPDATE_CONTACT_NAME_SUCCESS,
  STATEMENT_SUCCESS,
  STATEMENT_FAILED,
  UPDATE_BUSINESS_CONTACT,
  FETCH_PARTY_STATEMENT_SHARE_REQUEST,
  FETCH_PARTY_STATEMENT_SHARE_SUCCESS,
  FETCH_PARTY_STATEMENT_SHARE_FAILURE,
  SHARE_STATEMENT_SUCCESS,
  SHARE_PARTY_STATEMENT_FAILURE,
  UPDATE_PARTY_SUCCESS,
  UPDATE_PARTY_FAILED,
  GET_PARTY_PROFILE_REQUESTED,
  GET_PARTY_PROFILE_SUCCESS,
  GET_PARTY_PROFILE_FAILED,
  FETCH_VOUCHERS_SUMMARY_SUCCESS,
  FETCH_VOUCHERS_SUMMARY_FAILED,
  FETCH_UNRECONCILED_VOUCHERS_SUCCESS,
  FETCH_UNRECONCILED_VOUCHERS_FAILED,
  FETCH_UNRECONCILED_VOUCHERS_INITIATED,
  FETCH_INPROGRESS_VOUCHERS_SUCCESS,
  FETCH_INPROGRESS_VOUCHERS_INITIATED,
  FETCH_INPROGRESS_VOUCHERS_FAILED,
  STATEMENT_LOADING,
  HANDLE_TOGGLE_MODAL,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILED,
  RECONCILE_VOUCHERS_SUCCESS,
  RECONCILE_VOUCHERS_FAILED,
  FETCH_PARTY_RELATION_TYPES_INITIATED,
  FETCH_PARTY_RELATION_TYPES_SUCCESS,
  FETCH_PARTY_RELATION_TYPES_FAILED,
  FETCH_TRANSPORTER_PARTY_SUCCESS,
  FETCH_TRANSPORTER_PARTY_FAILED,
  FETCH_CONTACTS_REQUESTED,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILED,
  UPDATE_SALES_DISCOUNT_REQUEST,
  UPDATE_SALES_DISCOUNT_SUCCESS,
  UPDATE_SALES_DISCOUNT_ERROR,
  UPDATE_NAME_IN_DRAWER,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED,
  UPDATE_SHIPPING_ADDRESS_REQUEST,
  UPDATE_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_SHIPPING_ADDRESS_ERROR,
  UPDATE_BANK_DETAILS_REQUEST,
  UPDATE_BANK_DETAILS_SUCCESS,
  UPDATE_BANK_DETAILS_ERROR,
  FETCH_GSTN_DETAILS_REQUESTED,
  FETCH_GSTN_DETAILS_SUCCESS,
  FETCH_GSTN_DETAILS_FAILED,
  FETCH_PARTY_SALES_DISCOUNT_REQUESTED,
  FETCH_PARTY_SALES_DISCOUNT_SUCCESS,
  FETCH_PARTY_SALES_DISCOUNT_FAILED,
  RECONCILE_VOUCHERS_ERROR_RESET,
  HANDLE_TOGGLE_VOUCHER_VIEW_MODE,
  TOGGLE_PARTY_PROFILE_MODAL,
  ADD_PARTY_PICKER_FIELD_DATA,
  FETCH_ACCOUNT_ID_SUCCESS,
  FETCH_PARTY_ACCOUNT_ID_FAILED,
  PARTY_SEARCH_REQUESTED,
  PARTY_SEARCH_SUCCESS,
  PARTY_SEARCH_FAILED,
  FETCH_PARTY_CARD_SUCCESS,
  FETCH_PARTY_CARD_FAILED,
  FETCH_ACCOUNT_CARD_SUCCESS,
  FETCH_ACCOUNT_CARD_FAILED,
  STATEMENT_PAGINATE_SUCCESS,
  STATEMENT_PAGINATE_FAILED,
  SET_STATEMENT_BRANCH_FILTER,
  SET_INTERVAL,
  RESET_PARTY_FILTERS,
  STATEMENT_DATE_SET,
  PARTY_LIST_ITEM_UPDATE,
  SET_PARTY_ACCOUNT_LIST_FILTER,
  RESET_PARTY_ACCOUNT_LIST_FILTER,
  LOAD_NEW_RECONCILE_AMOUNT,
  STATEMENT_PAGINATE_LOADING,
  RECONCILATION_INITIATED,
  SET_FILTER_DATES,
  SET_RECONCILE_DATE,
  FETCH_PARTY_TAGS_REQUESTED,
  FETCH_PARTY_TAGS_SUCCESS,
  FETCH_PARTY_TAGS_FAILED,
  ADD_PARTY_TAG_REQUESTED,
  ADD_PARTY_TAG_SUCCESS,
  ADD_PARTY_TAG_IN_SELECTED,
  ADD_PARTY_TAG_FAILED,
  UPDATE_PARTY_TAG_REQUESTED,
  UPDATE_PARTY_TAG_SUCCESS,
  UPDATE_PARTY_TAG_IN_SELECTED,
  UPDATE_PARTY_TAG_FAILED,
  DELETE_PARTY_TAG_REQUESTED,
  DELETE_PARTY_TAG_SUCCESS,
  DELETE_PARTY_TAG_IN_SELECTED,
  DELETE_PARTY_TAG_FAILED,
  HANDLE_CHANGE_PARTY_TAGS,
  ADD_TAGS_TO_PARTIES_REQUESTED,
  ADD_TAGS_TO_PARTIES_SUCCESS,
  ADD_TAGS_TO_PARTIES_FAILED,
  FETCH_AJ_TALLY_PARTIES_REQUEST,
  FETCH_AJ_TALLY_PARTIES_SUCCESS,
  FETCH_AJ_TALLY_PARTIES_ERROR,
  UPDATE_PARTY_REQUESTED,
  HIDDEN_VOUCHER_LIST_REQUESTED,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE,
  REMOVE_TAGS_FROM_PARTY_FAILED,
  REMOVE_TAGS_FROM_PARTY_SUCCESS,
  REMOVE_TAGS_FROM_PARTY_REQUESTED,
  FETCH_CONTACTS_SEARCH_SUCCESS,
  FETCH_CONTACTS_SEARCH_FAILED,
  FETCH_CONTACTS_SEARCH_REQUESTED,
  REQUEST_PRIMARY_CONTACT_REQUESTED,
  REQUEST_PRIMARY_CONTACT_SUCCESS,
  REQUEST_PRIMARY_CONTACT_FAILED,
  ADD_SECONDARY_CONTACT_REQUESTED,
  ADD_SECONDARY_CONTACT_SUCCESS,
  ADD_SECONDARY_CONTACT_FAILED,
  UPDATE_CONTACT_REQUESTED,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILED,
  GET_CURRENT_BALANCE_SUCCESS,
  GET_CURRENT_BALANCE_REQUESTED,
  GET_CURRENT_BALANCE_FAILED,
  SET_DUPLICATE_VOUCHER_TYPE,
  REPORTS_DATA_FAILED,
  PROGRESS_TRACKER,
  FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED,
  FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS,
  FETCH_ALL_AJ_TALLY_BRANCHES_FAILED,
  SET_PARTY_SALES_DISCOUNT,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
  SET_STATEMENT_INCLUDE_TYPE_FILTER,
  HANDLE_PARTY_CREATION_MODE,
  SET_INTEREST_CALCULATION_DATE,
  SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE,
  RESET_INTEREST_CALCULATION_FILTERS,
  FETCH_INTEREST_CALCULATION_REQUESTED,
  FETCH_INTEREST_CALCULATION_SUCCESS,
  FETCH_INTEREST_CALCULATION_FAILED,
  SET_INTEREST_CALCULATION_BRANCH,
  FETCH_PARTY_PURCHASE_DISCOUNT_REQUESTED,
  FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS,
  FETCH_PARTY_PURCHASE_DISCOUNT_FAILED,
  SET_PARTY_PURCHASE_DISCOUNT
} from './types';

import * as api from '../api/contacts';
import * as companiesApi from '../api/companies';
import {
  parseError,
  formatDate,
  getValuesByKey,
  getFormattedDateIso,
  getPageFromPath,
  getFormattedDate3,
  getEndTimeOfDay
} from '../utils/helperFunctions';
import { openSnackbar } from './snackbar';
import {
  setReadLock,
  setUpdateLock,
  handleAccessToggleClick,
  handleHideVoucherByPartyStatement,
  fetchHideVoucherByPartyStatement,
  handleUnhideVoucherByPartyStatement
} from '../api/vouchers';
import { selectionReset } from './table';
import { setItemProperty, setItemPartyId } from './items';
import map from 'lodash/map';
import { reduce } from 'lodash';
import {
  recalculateVoucher,
  resetSelectedVoucher,
  setIncludeTypeQuery,
  trackExportStatus
} from '.';
import { getStatus, updateUserVoucherPartySettings } from './companies';
import VoucherCalModule from 'voucher-calculation';

const VoucherOptimizePureCal = VoucherCalModule.optimizePureCal;
const CONST_ERROR_MESSAGE = 'There was a error, please try again';

export const handlePartyAccessToggleClick = (contact, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  handleAccessToggleClick(companyId, contact).then(() => {
    callback && callback();
  });
};

const getCurrentPage = (dataCount, pageSize = 20) => {
  let currentPage = 1;
  if (dataCount > pageSize) {
    if (dataCount % pageSize === 0) {
      currentPage = dataCount / pageSize;
    } else {
      currentPage = parseInt(dataCount / pageSize, 10) + 1;
    }
  }
  return currentPage;
};

export const getHiddenAccVouchers = (
  page,
  accId,
  branchId = [],
  startDateQuery = null,
  endDateQuery = null,
  callback
) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  dispatch({ type: HIDDEN_VOUCHER_LIST_REQUESTED });
  return fetchHideVoucherByPartyStatement(
    companyId,
    '',
    startDateQuery || startDate,
    endDateQuery || endDate,
    page,
    [accId],
    getValuesByKey(branchId)
  )
    .then(res => {
      dispatch({
        type: 'HIDDEN_VOUCHER_LIST_SUCCESS',
        payload: res.data
      });
      callback && callback(res.data);
    })
    .catch(error => {
      serverError(error);
    });
};

export const unhideVouchers = (voucherIds, filters, key = PARTY_STATEMENT) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: companyId },
    contacts: { refAccountId }
  } = getState();
  const payload = voucherIds;
  return handleUnhideVoucherByPartyStatement(companyId, payload)
    .then(() => {
      const { selectedBranch, startDate, endDate } = filters;
      dispatch(getHiddenAccVouchers(1, refAccountId, selectedBranch, startDate, endDate));
      dispatch(handleStatementPaginate(companyId, null, null, endDate, key, 1));
      dispatch({ type: 'SELECT_RESET', key: 'hidden-type-voucher' });
      dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
      dispatch(openSnackbar('Vouchers Unhidden'));
    })
    .catch(error => {
      serverError(error);
    });
};

export const hideVouchers = (voucherIds, partyId, key) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  const payload = voucherIds;
  return handleHideVoucherByPartyStatement(companyId, payload)
    .then(() => {
      dispatch(onSuccessLock(companyId, startDate, endDate, partyId, key));
    })
    .catch(error => {
      serverError(error);
    });
};

export const voucherReadLock = (voucherIds, lock, partyId, key) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  const payload = {
    voucherIds,
    readLockEnabled: lock
  };
  return setReadLock(companyId, payload)
    .then(() => {
      dispatch(onSuccessLock(companyId, startDate, endDate, partyId, key));
    })
    .catch(error => {
      serverError(error);
    });
};

export const voucherUpdateLock = (voucherIds, lock, partyId, key) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  const payload = {
    voucherIds,
    updateLockEnabled: lock
  };
  return setUpdateLock(companyId, payload)
    .then(() => {
      dispatch(onSuccessLock(companyId, startDate, endDate, partyId, key));
    })
    .catch(error => {
      serverError(error);
    });
};

const onSuccessLock = (companyId, startDate, endDate, partyId, key) => dispatch => {
  dispatch(handleStatementPaginate(companyId, partyId, startDate, endDate, key, 1));
  dispatch({ type: 'SELECT_RESET', key: 'party-statement-table' });
  dispatch({ type: 'RESET_SELECTED_ROW_IDS' });
  dispatch(openSnackbar('Vouchers Locked'));
};

export const storeBusinessAccounts = payload => {
  return {
    type: BUSINESS_ACCOUNTS,
    payload: payload
  };
};

export const updatePartyImage = (partyId, obj, callback, storeKey) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  let payload = {
    businessId: partyId,
    ...obj
  };
  companiesApi
    .updateImage(iCompanyId, obj.id, payload)
    .then(res => {
      dispatch(openSnackbar('Profile Picture Updated'));
      partyId && dispatch(fetchPartyProfile(partyId, storeKey));
      callback && callback(res);
    })
    .catch(() => {
      dispatch(openSnackbar('Action Failed', ERROR));
    });
};

export const updateContactImage = (contactId, obj, callback, storeKey) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      partyProfile: { id }
    }
  } = getState();
  let payload = {
    contactId,
    ...obj
  };
  companiesApi
    .updateImage(iCompanyId, obj.id, payload)
    .then(() => {
      dispatch(openSnackbar('Contact Profile Picture Updated'));
      id && dispatch(fetchPartyProfile(id, storeKey));
      callback && callback();
    })
    .catch(() => {
      dispatch(openSnackbar('Action Failed', ERROR));
    });
};

export const togglePartyProfileModal = (value, tabIndex = CONTACTS) => ({
  type: TOGGLE_PARTY_PROFILE_MODAL,
  payload: { value, tabIndex }
});

export const getPartyStatementSharableLink = callback => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      selectedFilterDates: { startDate, endDate },
      refAccountId
    }
  } = getState();
  let query = `startDate=${startDate}&endDate=${endDate}&statementType=all-unreconciled&templateId=51754`;
  api
    .printPartyStatement(iCompanyId, refAccountId, query)
    .then(res => {
      // window.open(res.data.response, '_blank').focus();
      callback && callback(res.data.response);
    })
    .catch(() => {
      dispatch(openSnackbar(' Action Failed', ERROR));
    });
};

export const printPartyStatement = (partyId, type, templateId, date) => (dispatch, getState) => {
  if (templateId === '') {
    dispatch(openSnackbar('Print template is empty.', ERROR));
    return;
  }
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      filterBranches,
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  let query = `statementType=${type}&templateId=${templateId}`;
  if (type === 'reconciled') {
    query += `&startDate=${date}&endDate=${date}`;
  } else {
    query += `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (filterBranches.length > 0) {
    query += `&iBranchIds${getValuesByKey(filterBranches).toString()}`;
  }
  mixpanel.track('party-statement-print');
  api
    .printPartyStatement(iCompanyId, partyId, query)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(() => {
      dispatch(openSnackbar('Print Action Failed', ERROR));
    });
};

export const printPartyEnvelop = partyId => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  mixpanel.track('party-statement-envelop-print');
  api
    .getPartyEnvelope(iCompanyId, partyId)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(() => {
      dispatch(openSnackbar('Print Action Failed', ERROR));
    });
};

export const setContactProfilePic = (contactId, payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  api
    .setContactImage(iCompanyId, contactId, payload)
    .then(() => {
      dispatch(openSnackbar('Contact Profile Pic Updated Successfully'));
      callback && callback();
    })
    .catch(() => {
      dispatch(openSnackbar('Action Failed', ERROR));
    });
};

export const setPartyProfilePic = (partyId, payload, callback, storeKey) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  companiesApi
    .setPartyProfileImage(iCompanyId, partyId, payload)
    .then(res => {
      dispatch(openSnackbar('Profile Picture Updated'));
      partyId && dispatch(fetchPartyProfile(partyId, storeKey));
      callback && callback(res);
    })
    .catch(() => {
      dispatch(openSnackbar('Action Failed', ERROR));
    });
};

export const addInternalNotes = (payload, key = 'Business', callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  api
    .addInternalNotes(iCompanyId, key, payload)
    .then(() => {
      dispatch(openSnackbar('Internal Notes Added Successfully'));
      callback && callback();
    })
    .catch(error => {
      console.log(error);
    });
};

export const getInternalNotes = (id, key = 'Business', page = 1, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  api
    .fetchInternalNotes(iCompanyId, id, page)
    .then(res => {
      dispatch({ type: 'SET_INTERNAL_NOTES', payload: res, key });
      callback && callback(res);
    })
    .catch(error => {
      console.log(error);
    });
};

export const updateInternalNotes = (id, payload, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  api
    .updateInternalNotes(iCompanyId, id, payload)
    .then(res => {
      dispatch(openSnackbar('Internal Notes Updated'));
      callback && callback(res);
    })
    .catch(error => {
      console.log(error);
    });
};

export const deleteInternalNotes = (id, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  api
    .deleteInternalNotes(iCompanyId, id)
    .then(res => {
      dispatch(openSnackbar('Internal Notes Deleted'));
      callback && callback(res);
    })
    .catch(error => {
      console.log(error);
    });
};

export const addEditDeletePartyInternalNotes = (payload, key = 'add', callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      partyProfile: { id }
    }
  } = getState();

  api
    .updatePartyInternalNotes(iCompanyId, id, payload)
    .then(() => {
      if (key === 'add') {
        dispatch(openSnackbar('Internal Notes Added Successfully'));
      } else if (key === 'update') {
        dispatch(openSnackbar('Internal Notes Updated Successfully'));
      } else {
        dispatch(openSnackbar('Internal Notes Deleted Successfully', ERROR));
      }
      callback && callback();
    })
    .catch(() => {
      dispatch(openSnackbar('Print Action Failed', ERROR));
    });
};

export const fetchContacts = () => (dispatch, getState) => {
  dispatch({ type: CONTACT_LIST_REQUESTED, payload: {} });
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  api
    .fetchContacts(iCompanyId)
    .then(response => {
      dispatch({ type: BUSINESS_ACCOUNTS, response: response && response[0] ? response : [] });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: CONTACT_LIST_FAILED, payload: error });
    });
};

const partySearch = (iCompanyId, pageNo, query, key) => {
  if (key === CONTACTS) {
    return api.partySearchViaContact(iCompanyId, pageNo, query);
  }
  return api.partySearch(iCompanyId, pageNo, query);
};

export const handlePartySearch = (
  relationType = '',
  pageNo = 1,
  searchTextParam = '',
  searchBy = '',
  storeKey = PARTY_LIST_DATA,
  partyId
) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    partyAccountListFilters: {
      [storeKey]: { selectedFilterType, selectedSearchBy, searchText, includePartyTags }
    }
  } = getState();
  let key = null;
  dispatch({ type: PARTY_SEARCH_REQUESTED, page: pageNo, storeKey });
  let query = '';
  if (relationType || selectedFilterType) {
    query += `relationType=${relationType || selectedFilterType}&`;
  }
  if (searchTextParam || searchText) {
    query += `searchText=${searchTextParam || searchText}&`;
  }
  if (searchBy || selectedSearchBy) {
    query += `searchBy=${searchBy || selectedSearchBy}&`;
  }
  query += `&includePartyTags=${includePartyTags}`;

  if ((searchText && !isNaN(searchText)) || (searchTextParam && !isNaN(searchTextParam))) {
    key = CONTACTS;
  }

  partySearch(iCompanyId, pageNo, query, key)
    .then(response => {
      dispatch({
        type: PARTY_SEARCH_SUCCESS,
        payload: response,
        storeKey,
        partyId,
        infiniteScroll: storeKey === PARTY_LIST_DATA
      });
    })
    .catch(error => {
      dispatch({ type: PARTY_SEARCH_FAILED, storeKey, payload: error });
      serverError(error);
    });
};

export const handleTallyPartySearch = (
  relationType = '',
  pageNo = 1,
  searchTextParam = '',
  searchBy = 'name',
  storeKey = PARTY_LIST_DATA,
  partyId,
  companyType = 'aj'
) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    partyAccountListFilters: {
      [storeKey]: { searchText }
    }
  } = getState();
  dispatch({ type: PARTY_SEARCH_REQUESTED, page: pageNo, storeKey });
  let query = `searchBy=name`;
  if (companyType) {
    query += `&companyType=${companyType}`;
  }
  if (searchTextParam || searchText) {
    query += `&text=${searchTextParam || searchText}`;
  }
  api
    .ajTallyPartySearch(iCompanyId, pageNo, query)
    .then(response => {
      dispatch({ type: PARTY_SEARCH_SUCCESS, payload: response, storeKey, partyId });
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: PARTY_SEARCH_FAILED, payload: error });
    });
};

export const handleTallyBranch = (companyType = AJ) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED });
  let query = '';
  if (companyType) {
    query += `&companyType=${companyType}`;
  }
  api
    .fetchAjTallyBranch(iCompanyId, query)
    .then(response => {
      console.table(response);
      dispatch({ type: FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS, payload: response });
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: FETCH_ALL_AJ_TALLY_BRANCHES_FAILED });
    });
};

export const fetchAjTallyParties = (vouchers, type, callback, failCallback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_AJ_TALLY_PARTIES_REQUEST });

  const uniqueVouchers = vouchers.reduce((acc, current) => {
    const x = acc.find(item => item.party.refId === current.party.refId);
    if (!x) {
      acc.push(current);
    }
    return acc;
  }, []);

  Promise.all(
    map(uniqueVouchers, voucher => api.fetchAjTallyParty(iCompanyId, voucher.party.refId, type))
  )
    .then(response => {
      const partiesObj = reduce(
        response,
        (prev, curr) => {
          if (curr.length) {
            prev[curr[0]._oPId] = curr[0];
          }
          return prev;
        },
        {}
      );
      dispatch({ type: FETCH_AJ_TALLY_PARTIES_SUCCESS, payload: partiesObj });
      callback && callback(partiesObj);
    })
    .catch(() => {
      dispatch({ type: FETCH_AJ_TALLY_PARTIES_ERROR, payload: CONST_ERROR_MESSAGE });
      failCallback && failCallback();
    });
};

export const fetchPartyStatement = (
  companyId,
  partyId,
  queryStartDate,
  queryEndDate,
  storeKey,
  branchesString
) => (dispatch, getState) => {
  const {
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  let tempStartDate = queryStartDate || startDate;
  let tempEndDate = queryEndDate || endDate;
  dispatch({ type: STATEMENT_LOADING, key: storeKey });
  api
    .fetchPartyStatement(companyId, partyId, tempStartDate, tempEndDate, branchesString)
    .then(response => {
      response.startDate = tempStartDate;
      response.endDate = tempEndDate;
      dispatch({ type: STATEMENT_SUCCESS, key: storeKey, payload: response });
    })
    .catch(error => {
      dispatch({ type: STATEMENT_FAILED, key: storeKey, payload: error });
    });
};

export const setStatementDates = (start, end, storeKey) => dispatch => {
  dispatch({ type: STATEMENT_DATE_SET, key: storeKey, start, end });
};

export const handleStatementPaginate = (
  companyId,
  selectedId,
  queryStartDate,
  queryEndDate,
  storeKey,
  pageNo = 1,
  loader = true
) => (dispatch, getState) => {
  const {
    contacts: {
      filterBranches,
      selectedFilterDates: { startDate, endDate },
      includeType
    },
    currentCompany: { id: iCompanyId },
    table: { sorting: sort }
  } = getState();
  loader && dispatch({ type: STATEMENT_PAGINATE_LOADING, key: storeKey });
  let paginateApi = api.accountStatementPagination;
  let tempStartDate = queryStartDate || formatDate(moment(startDate));
  let tempEndDate = queryEndDate || formatDate(moment(endDate));
  let query = `startDate=${getFormattedDateIso(
    moment(tempStartDate)
  )}&endDate=${getFormattedDateIso(moment(tempEndDate))}`;

  if (storeKey === PARTY_STATEMENT) query += `&statementType=${VOUCHER_TYPE_ALL}`;
  else query += `&statementType=${ALL_UNRECONCILED}`;

  if (!selectedId) return;

  if (filterBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(filterBranches).toString()}`;
  }
  if (sort.length > 0) {
    query += `&sort=${sort[0].columnName} ${
      sort[0].direction === TABLE_ASCENDING ? ASCENDING : DESCENDING
    }`;
  }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }

  paginateApi(iCompanyId, selectedId, pageNo, query)
    .then(response => {
      response.startDate = tempStartDate;
      response.endDate = tempEndDate;
      dispatch({ type: STATEMENT_PAGINATE_SUCCESS, key: storeKey, payload: response });
    })
    .catch(error => {
      serverError(error);
      dispatch({
        type: STATEMENT_PAGINATE_FAILED,
        key: storeKey,
        payload: {
          page: 0,
          hitsPerPage: 0,
          totalDataCount: 0,
          totalPages: 0,
          data: {
            openingBalanceSummary: {},
            closingBalanceSummary: {},
            statement: [],
            reconciledBalanceSummary: {},
            balanceForStatement: {}
          }
        }
      });
    });
};

export const nameCheckformPhone = phone => (dispatch, getState) => {
  const { currentCompany } = getState();
  api
    .checkForName(currentCompany.id, phone)
    .then(response => dispatch({ type: CHECK_FOR_NAME_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: CHECK_FOR_NAME_FAILED }));
};

export const updateContactName = (id, name) => (dispatch, getState) => {
  const { currentCompany } = getState();
  api
    .updateContactName(currentCompany.id, id, name)
    .then(
      response =>
        response.status === 200 &&
        response.statusText === 'OK' &&
        dispatch({ type: UPDATE_CONTACT_NAME_SUCCESS, status: 'success' })
    )
    .catch(() => dispatch({ type: UPDATE_CONTACT_NAME_FAILED, status: 'failure' }));
};

export const getPartyById = (partyId, callback) => (dispatch, getState) => {
  const { currentCompany } = getState();
  if (!partyId) return;
  api
    .fetchPartyById(currentCompany.id, partyId)
    .then(response => {
      callback && callback(response);
      return response;
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: UPDATE_CONTACT_NAME_FAILED, status: 'failure' });
      callback(err.response);
    });
};

export const addContactSave = (payload, key, callback) => (dispatch, getState) => {
  const {
    currentCompany,
    router
    // contacts: {
    //   partyListData: { page }
    // }
  } = getState();
  dispatch({ type: ADD_CONTACT_DATA_REQUEST });
  mixpanel.track('save-party-click', {
    partyName: payload.businessData.name,
    companyName: currentCompany.name
  });
  api
    .addContact(currentCompany.id, payload)
    .then(response => response.data)
    .then(response => {
      dispatch(fetchAccountsCurrentBalance(response.refAccountId));
      if (
        router.location.pathname.includes('/home/parties') &&
        payload.businessData.relationType !== TRANSPORTER
      ) {
        navigate(dispatch, `/${currentCompany.id}/home/parties/${response.id}/list`);
      }
      dispatch({ type: ADD_CONTACT_DATA_SUCCESSS, response });
      dispatch(toggleModalView(key, false));
      key === ADD_SALES_PERSON_MODAL
        ? dispatch(updateSalesPersonDetails(response))
        : dispatch(updatePartyInField(response));
      dispatch(handlePartyTagSelect('', []));
      dispatch(fetchPartySalesDiscount(response.id));
      dispatch(fetchPartyPurchaseDiscount(response.id));

      if (payload.businessData.relationType === TRANSPORTER) {
        //if transporter added fetch refreshed transporter list
        dispatch(fetchTransporterParties());
      }
      dispatch(handlePartySearch());
      callback && callback(response.id);
    })
    .catch(e => {
      let errMsg = '';
      if (e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
        errMsg = parseError(e.response.data.error.message);
      }
      dispatch({ type: ADD_CONTACT_DATA_FAILED });
      dispatch(openSnackbar(errMsg || CONST_ERROR_MESSAGE, ERROR));
    });
};

export const fetchPartyStatementShare = (companyId, accId, storeKey) => dispatch => {
  dispatch({ type: FETCH_PARTY_STATEMENT_SHARE_REQUEST });
  api
    .getPartyStatementShare(companyId, accId)
    .then(response => {
      dispatch({ type: FETCH_PARTY_STATEMENT_SHARE_SUCCESS, key: storeKey, payload: response });
    })
    .catch(error =>
      dispatch({ type: FETCH_PARTY_STATEMENT_SHARE_FAILURE, key: storeKey, payload: error })
    );
};

export const fetchPartyStatementPublicShareStatus = (companyId, accId, storeKey) => dispatch => {
  dispatch({ type: FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST, key: storeKey });
  api
    .getPartyStatementPublicShareStatus(companyId, accId)
    .then(response => {
      const data = response.data[response.data.length - 1]; //show last shared link
      dispatch({
        type: FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS,
        key: storeKey,
        payload: data
      });
    })
    .catch(error =>
      dispatch({ type: FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE, key: storeKey, payload: error })
    );
};

export const fetchPartyBusinessPublicShareStatus = (companyId, accId, storeKey) => dispatch => {
  dispatch({ type: FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED, key: storeKey });
  api
    .fetchPartyBusinessPublicShareStatus(companyId, accId)
    .then(response => {
      const data = response.data[0]; //show last shared link
      dispatch({
        type: FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
        key: storeKey,
        payload: data
      });
    })
    .catch(error =>
      dispatch({
        type: FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
        key: storeKey,
        payload: error
      })
    );
};

export const sendPartyStatement = (companyId, partyId, data, storeKey, callback) => dispatch => {
  mixpanel.track('share-party-statement');
  api
    .sendPartyStatement(companyId, partyId, data)
    .then(response => {
      dispatch({ type: SHARE_STATEMENT_SUCCESS, key: storeKey, payload: response });
      !data.generateLink && dispatch(openSnackbar('Party shared Successfully'));
      callback && callback(response);
    })
    .catch(error => {
      dispatch({ type: SHARE_PARTY_STATEMENT_FAILURE, key: storeKey, payload: error });
      serverError(error);
      callback && callback(error?.response?.data);
    });
};

export const fetchPartyProfile = (
  partyId,
  storeKey,
  ifUpdatePartyList,
  ifUpdateBusinessContact = true
) => (dispatch, getState) => {
  dispatch({ type: GET_PARTY_PROFILE_REQUESTED, key: storeKey });
  const {
    currentCompany: { id: iCompanyId },
    vouchers: { voucherItems }
  } = getState();
  api
    .fetchPartyProfile(iCompanyId, partyId)
    .then(response => {
      dispatch({ type: GET_PARTY_PROFILE_SUCCESS, key: storeKey, payload: response });
      dispatch({ type: GET_PARTY_PROFILE_SUCCESS, key: 'partyProfile', payload: response });
      ifUpdateBusinessContact &&
        dispatch({
          type: UPDATE_BUSINESS_CONTACT,
          payload: {
            party: response,
            business: response
          }
        });
      response.defaultSalesDiscount &&
        voucherItems.length > 0 &&
        dispatch({
          type: SET_PARTY_SALES_DISCOUNT,
          payload: VoucherOptimizePureCal.addSalesDiscount(
            response.salesDiscounts,
            voucherItems,
            response.defaultSalesDiscountValue
          )
        });

      response.defaultPurchaseDiscount &&
        voucherItems.length > 0 &&
        dispatch({
          type: SET_PARTY_PURCHASE_DISCOUNT,
          payload: VoucherOptimizePureCal.addPurchaseDiscount(
            response.purchaseDiscounts,
            voucherItems,
            response.defaultPurchaseDiscountValue
          )
        });
      ifUpdatePartyList && dispatch({ type: PARTY_LIST_ITEM_UPDATE, response, partyId });
    })
    .catch(error => dispatch({ type: GET_PARTY_PROFILE_FAILED, payload: error, key: storeKey }));
};

export const updatePartyName = (
  companyId,
  partyId,
  data,
  storeKey,
  modalKey,
  callback
) => dispatch => {
  api
    .updatePartyName(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey, true));
      dispatch(toggleModalView(modalKey, false));
      callback && callback();
      if (data.relationType === TRANSPORTER)
        //if transporter added fetch refreshed transporter list
        dispatch(fetchTransporterParties());
    })
    .catch(error => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      callback && callback();
      serverError(error);
    });
};

export const updateOpeningBalance = (companyId, partyId, data, storeKey, modalKey, callback) => (
  dispatch,
  getState
) => {
  const {
    contacts: {
      selectedFilterDates: { startDate, endDate },
      refAccountId
    }
  } = getState();
  api
    .updateOpeningBalance(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(
        handleStatementPaginate(companyId, refAccountId, startDate, endDate, 'partyStatement')
      );
      dispatch(fetchAccountsCurrentBalance(refAccountId));
      dispatch(toggleModalView(modalKey, false));
      callback && callback();
    })
    .catch(error => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      serverError(error);
      callback && callback();
    });
};

export const updateContact = (data, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: UPDATE_CONTACT_REQUESTED });
  api
    .updateContact(iCompanyId, data.id, data)
    .then(response => {
      dispatch({ type: UPDATE_CONTACT_SUCCESS });
      dispatch(openSnackbar('Contact updated Successfully'));
      callback && callback(data);
    })
    .catch(err => {
      const errMsg =
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
          ? err.response.data.error.message
          : CONST_ERROR_MESSAGE;
      dispatch(openSnackbar(errMsg, ERROR));
      dispatch({ type: UPDATE_CONTACT_FAILED });
    });
};

export const addContact = (companyId, partyId, data, storeKey, modalKey, callback) => dispatch => {
  dispatch({ type: ADD_SECONDARY_CONTACT_REQUESTED });
  api
    .addPartyContact(companyId, partyId, data)
    .then(response => {
      dispatch({ type: ADD_SECONDARY_CONTACT_SUCCESS });
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(toggleModalView(modalKey, false));
      callback && callback(response);
    })
    .catch(err => {
      const errMsg =
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
          ? err.response.data.error.message
          : CONST_ERROR_MESSAGE;
      dispatch({ type: ADD_SECONDARY_CONTACT_FAILED });
      dispatch({
        type: UPDATE_PARTY_FAILED,
        key: storeKey,
        payload: errMsg
      });
      dispatch(openSnackbar(errMsg, ERROR));
    });
};

export const resetPartyProfileErrorText = () => {
  return {
    type: UPDATE_PARTY_FAILED,
    key: PARTY_PROFILE,
    payload: ''
  };
};

export const replacePrimary = (
  companyId,
  partyId,
  data,
  storeKey,
  modalKey,
  callback
) => dispatch => {
  const {
    newPrimaryData: { id }
  } = data;
  dispatch({ type: REQUEST_PRIMARY_CONTACT_REQUESTED });
  api
    .replacePrimaryContact(companyId, partyId, data)
    .then(response => {
      dispatch({ type: REQUEST_PRIMARY_CONTACT_SUCCESS });
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(toggleModalView(modalKey, false));
      dispatch(handleFetchPartyBillingAndContactCard(companyId, partyId));
      dispatch(fetchContact(companyId, id));
      callback && callback();
    })
    .catch(err => {
      dispatch(openSnackbar(err.response.data.error.message, ERROR));
      dispatch({ type: REQUEST_PRIMARY_CONTACT_FAILED });
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
    });
};

export const markContactAsPrimary = (
  companyId,
  partyId,
  contactId,
  storeKey,
  modalKey
) => dispatch => {
  api
    .markContactAsPrimary(companyId, partyId, contactId)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey, true));
      dispatch(toggleModalView(modalKey, false));
    })
    .catch(() =>
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE })
    );
};

export const deleteContact = (companyId, partyId, contactId, storeKey, modalKey) => dispatch => {
  api
    .deleteContact(companyId, partyId, contactId)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
    })
    .catch(() =>
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE })
    );
};

export const deleteParty = callback => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId },
    contacts: { refAccountId }
  } = getState();
  api
    .deleteAccount(companyId, refAccountId)
    .then(() => {
      dispatch(openSnackbar('Party Deleted Successfully'));
      dispatch(handlePartySearch());
      callback && callback(companyId);
    })
    .catch(err => {
      dispatch(openSnackbar(err.response.data.error.message, ERROR));
      console.log(err.response);
    });
};

export const updateBillingDetails = (
  companyId,
  partyId,
  data,
  storeKey,
  modalKey,
  callback
) => dispatch => {
  api
    .updateBillingDetails(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey, true));
      dispatch(toggleModalView(modalKey, false));
      dispatch(openSnackbar('GST & Billing Information Updated'));
      callback && callback();
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      callback && callback();
    });
};

export const updateShippingAddr = (companyId, partyId, data, storeKey, modalKey) => dispatch => {
  dispatch({ type: UPDATE_SHIPPING_ADDRESS_REQUEST });
  api
    .updateShippingAddr(companyId, partyId, data.id, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Shipping Information Updated'));
      dispatch({ type: UPDATE_SHIPPING_ADDRESS_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      dispatch({ type: UPDATE_SHIPPING_ADDRESS_ERROR });
    });
};

export const addShippingAddr = (companyId, partyId, data, storeKey, modalKey) => dispatch => {
  dispatch({ type: UPDATE_SHIPPING_ADDRESS_REQUEST });
  api
    .addShippingAddr(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Shipping Information Added'));
      dispatch({ type: UPDATE_SHIPPING_ADDRESS_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      dispatch({ type: UPDATE_SHIPPING_ADDRESS_ERROR });
    });
};

export const deleteShippingAddr = (
  companyId,
  partyId,
  shippingAddrId,
  storeKey,
  modalKey
) => dispatch => {
  api
    .deleteShippingAddr(companyId, partyId, shippingAddrId)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Shipping Information Deleted'));
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
    });
};

export const updateBankDetail = (companyId, partyId, data, storeKey, modalKey) => dispatch => {
  dispatch({ type: UPDATE_BANK_DETAILS_REQUEST });
  api
    .updateBankDetail(companyId, partyId, data.id, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Bank Details Updated'));
      dispatch({ type: UPDATE_BANK_DETAILS_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      dispatch({ type: UPDATE_BANK_DETAILS_ERROR });
    });
};

export const addBankDetail = (companyId, partyId, data, storeKey, modalKey) => dispatch => {
  dispatch({ type: UPDATE_BANK_DETAILS_REQUEST });
  api
    .addBankDetail(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Bank Details Added'));
      dispatch({ type: UPDATE_BANK_DETAILS_SUCCESS });
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      dispatch({ type: UPDATE_BANK_DETAILS_ERROR });
    });
};

export const deleteBankDetail = (
  companyId,
  partyId,
  bankDetailId,
  storeKey,
  modalKey
) => dispatch => {
  api
    .deleteBankDetail(companyId, partyId, bankDetailId)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(openSnackbar('Bank Details Deleted'));
    })
    .catch(() =>
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE })
    );
};

export const updateSalesDiscounts = (
  partyId,
  itemList,
  defaultSalesDiscountValue,
  defaultSalesDiscount,
  storeKey,
  modalKey
) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  dispatch({ type: UPDATE_SALES_DISCOUNT_REQUEST });
  let data = {};
  if (itemList) data.salesDiscount = itemList;
  if (defaultSalesDiscountValue) data.defaultSalesDiscountValue = defaultSalesDiscountValue;
  if (defaultSalesDiscount) data.defaultSalesDiscount = defaultSalesDiscount;
  api
    .updateSalesDiscounts(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_SALES_DISCOUNT_SUCCESS });
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(fetchPartySalesDiscount(partyId));
      dispatch(openSnackbar('Sales Discount Updated'));
    })
    .catch(() => {
      dispatch({ type: UPDATE_SALES_DISCOUNT_ERROR });
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
    });
};

export const updatePurchaseDiscounts = (
  partyId,
  itemList,
  defaultPurchaseDiscountValue,
  defaultPurchaseDiscount,
  storeKey,
  modalKey
) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  dispatch({ type: UPDATE_SALES_DISCOUNT_REQUEST });
  let data = {};
  if (itemList) data.purchaseDiscount = itemList;
  if (defaultPurchaseDiscountValue)
    data.defaultPurchaseDiscountValue = defaultPurchaseDiscountValue;
  if (defaultPurchaseDiscount) data.defaultPurchaseDiscount = defaultPurchaseDiscount;
  api
    .updatePurchaseDiscounts(companyId, partyId, data)
    .then(response => {
      dispatch({ type: UPDATE_SALES_DISCOUNT_SUCCESS });
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(toggleModalView(modalKey, false));
      dispatch(fetchPartyProfile(partyId, storeKey));
      dispatch(fetchPartyPurchaseDiscount(partyId));
      dispatch(openSnackbar('Purchase Discount Updated'));
    })
    .catch(() => {
      dispatch({ type: UPDATE_SALES_DISCOUNT_ERROR });
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
    });
};

export const addPartyTransporter = (
  companyId,
  partyId,
  transporterId,
  storeKey,
  callback
) => dispatch => {
  dispatch({ type: UPDATE_PARTY_REQUESTED, key: storeKey });
  api
    .addPartyTransporter(companyId, partyId, { id: transporterId })
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(openSnackbar('Transpoter Added Successfully'));
      dispatch(fetchPartyProfile(partyId, storeKey));
      callback && callback();
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      callback && callback();
    });
};

export const deletePartyTransporter = (
  companyId,
  partyId,
  transporterId,
  storeKey,
  callback
) => dispatch => {
  api
    .deletePartyTransporter(companyId, partyId, transporterId)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey));
      callback && callback();
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      dispatch(openSnackbar('Action Error', ERROR));
      callback && callback();
    });
};

export const fetchIfscCodeDetails = (companyId, ifscCode) => () => {
  return api.fetchIfscCode(companyId, ifscCode);
};

export const fetchContact = (companyId, contactId) => dispatch => {
  api
    .fetchContact(companyId, contactId)
    .then(response => {
      dispatch({ type: FETCH_CONTACT_SUCCESS, payload: response });
    })
    .catch(() => dispatch({ type: FETCH_CONTACT_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const handleFetchPartyBillingAndContactCard = (companyId, partyId) => dispatch => {
  api
    .fetchPartyCard(companyId, partyId)
    .then(response => {
      dispatch({ type: FETCH_PARTY_CARD_SUCCESS, payload: response });
    })
    .catch(() => dispatch({ type: FETCH_PARTY_CARD_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const handleFetchAccountCard = (companyId, accountId) => dispatch => {
  companiesApi
    .fetchCompanyAccountById(companyId, accountId)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNT_CARD_SUCCESS, payload: response });
    })
    .catch(() => dispatch({ type: FETCH_ACCOUNT_CARD_FAILED, payload: CONST_ERROR_MESSAGE }));
};

export const toggleModalView = (storeKey, value) => (dispatch, getState) => {
  const {
    currentCompany: { name },
    router: {
      location: { pathname }
    }
  } = getState();
  mixpanel.track(`save-edit-drawer-toggle-${storeKey}`, {
    key: storeKey,
    flag: value,
    companyName: name,
    page: getPageFromPath(pathname)
  });
  dispatch({ type: HANDLE_TOGGLE_MODAL, key: storeKey, payload: value });
  // if (value) dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: '' });
};

export const updateCreditDays = (
  companyId,
  partyId,
  creditDays,
  storeKey,
  modalKey,
  callback
) => dispatch => {
  api
    .updateCreditDays(companyId, partyId, { creditDays: creditDays })
    .then(response => {
      dispatch({ type: UPDATE_PARTY_SUCCESS, key: storeKey, payload: response });
      dispatch(fetchPartyProfile(partyId, storeKey, true));
      dispatch(toggleModalView(modalKey, false));
      callback && callback();
    })
    .catch(() => {
      dispatch({ type: UPDATE_PARTY_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      callback && callback();
    });
};

export const fetchVouchersSummary = (
  companyId,
  partyId,
  startDate,
  endDate,
  storeKey,
  branchesString
) => dispatch => {
  api
    .fetchVouchersSummary(companyId, partyId, startDate, endDate, branchesString)
    .then(response => {
      // response.startDate = startDate;
      // response.endDate = endDate;
      dispatch({ type: FETCH_VOUCHERS_SUMMARY_SUCCESS, key: storeKey, payload: response });
    })
    .catch(error =>
      dispatch({ type: FETCH_VOUCHERS_SUMMARY_FAILED, key: storeKey, payload: error })
    );
};

export const setNewReconcileLoader = () => ({ type: LOAD_NEW_RECONCILE_AMOUNT, payload: true });

export const fetchUnreconciledVouchers = (
  companyId,
  selectedId,
  storeKey,
  pageNo = 1,
  hitPerPage = 20,
  loader = true
) => (dispatch, getState) => {
  const {
    contacts: {
      filterBranches,
      selectedFilterDates: { startDate, endDate },
      includeType
    },
    currentCompany: { id: companyId },
    table: { sorting: sort }
  } = getState();
  loader && dispatch({ type: FETCH_UNRECONCILED_VOUCHERS_INITIATED, key: storeKey });
  let type = storeKey === UNRECONCILED_VOUCHER_STATEMENT ? 'all-unreconciled' : UNRECONCILED;
  let tempStartDate = formatDate(moment(startDate));
  let tempEndDate = formatDate(moment(endDate));
  let query = `startDate=${getFormattedDateIso(
    moment(tempStartDate)
  )}&endDate=${getFormattedDateIso(
    moment(tempEndDate)
  )}&statementType=${type}&hitsPerPage=${hitPerPage}`;

  if (!selectedId) return;

  if (filterBranches.length > 0) {
    query += `&iBranchIds=${getValuesByKey(filterBranches).toString()}`;
  }
  if (sort.length > 0) {
    query += `&sort=${sort[0].columnName} ${
      sort[0].direction === TABLE_ASCENDING ? ASCENDING : DESCENDING
    }`;
  }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }

  api
    .fetchUnreconciledVouchers(companyId, selectedId, pageNo, query)
    .then(response => {
      const { page, hitsPerPage, totalDataCount, totalPages } = response;
      const tempResponse = {
        page,
        hitsPerPage,
        totalDataCount,
        totalPages,
        ...response.data
      };
      dispatch({
        type: FETCH_UNRECONCILED_VOUCHERS_SUCCESS,
        key: storeKey,
        payload: tempResponse
      });
      dispatch({ type: RECONCILE_VOUCHERS_ERROR_RESET, key: storeKey, payload: '' });
      dispatch({ type: LOAD_NEW_RECONCILE_AMOUNT, payload: false });
    })
    .catch(error => {
      serverError(error);
      dispatch({ type: LOAD_NEW_RECONCILE_AMOUNT, payload: false });
      dispatch({
        type: FETCH_UNRECONCILED_VOUCHERS_FAILED,
        key: storeKey,
        payload: {
          page: 0,
          hitsPerPage: 0,
          totalDataCount: 0,
          totalPages: 0,
          openingBalanceSummary: {},
          closingBalanceSummary: {},
          statement: [],
          reconciledBalanceSummary: {},
          balanceForStatement: {}
        }
      });
    });
};

export const fetchInProgressVouchers = (
  companyId,
  selectedId,
  storeKey,
  pageNo = 1,
  loader = true
) => (dispatch, getState) => {
  const {
    contacts: {
      filterBranches,
      selectedFilterDates: { startDate, endDate },
      includeType
    },
    table: { sorting: sort }
  } = getState();
  loader && dispatch({ type: FETCH_INPROGRESS_VOUCHERS_INITIATED, key: storeKey });

  let tempStartDate = formatDate(moment(startDate));
  let tempEndDate = formatDate(moment(endDate));
  let query = `startDate=${getFormattedDateIso(
    moment(tempStartDate)
  )}&endDate=${getFormattedDateIso(
    moment(tempEndDate)
  )}&statementType=in-progress&hitsPerPage=${RECONCILE_MODAL_PAGE_SIZE}`;

  if (!selectedId) return;

  if (filterBranches.length > 0) {
    query += `&branches=${getValuesByKey(filterBranches).toString()}`;
  }
  if (sort.length > 0) {
    query += `&sort=${sort[0].columnName} ${
      sort[0].direction === TABLE_ASCENDING ? ASCENDING : DESCENDING
    }`;
  }

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }

  api
    .fetchUnreconciledVouchers(companyId, selectedId, pageNo, query)
    .then(response => {
      const { page, hitsPerPage, totalDataCount, totalPages } = response;
      const tempResponse = {
        page,
        hitsPerPage,
        totalDataCount,
        totalPages,
        ...response.data
      };
      dispatch({
        type: FETCH_INPROGRESS_VOUCHERS_SUCCESS,
        key: storeKey,
        payload: tempResponse
      });
      dispatch({ type: RECONCILE_VOUCHERS_ERROR_RESET, key: storeKey, payload: '' });
      dispatch({ type: LOAD_NEW_RECONCILE_AMOUNT, payload: false });
    })
    .catch(error => {
      dispatch({ type: LOAD_NEW_RECONCILE_AMOUNT, payload: false });
      dispatch({ type: FETCH_INPROGRESS_VOUCHERS_FAILED, key: storeKey, payload: error });
    });
};

export const reconcileVouchers = (
  companyId,
  partyId,
  data,
  storeKey,
  modalKey,
  action,
  callback,
  selectionMode = SELECTIVE
) => (dispatch, getState) => {
  const {
    contacts: { inProgressVouchers, unreconciledVouchers },
    contacts: { reconcileDate }
  } = getState();

  const timestamp =
    getFormattedDate3() === reconcileDate ? moment().format() : moment(reconcileDate).format();
  dispatch({ type: RECONCILATION_INITIATED });
  const payload = {
    selectionMode,
    data,
    timestamp: action === RECONCILED ? timestamp : undefined
  };
  mixpanel.track('reconcile-vouchers');
  api
    .reconcileVouchers(companyId, payload, partyId)
    .then(response => {
      const unreconcileDataCount =
        action === UNRECONCILED ? unreconciledVouchers.totalDataCount + data.length : 1;
      const inProgressDataCount =
        action === INPROGRESS ? inProgressVouchers.totalDataCount + data.length : 1;
      dispatch({ type: RECONCILE_VOUCHERS_SUCCESS, key: storeKey, payload: response });
      dispatch({ type: RECONCILE_VOUCHERS_ERROR_RESET, key: storeKey, payload: '' });
      dispatch(selectionReset());
      dispatch(toggleModalView(RECONCILED_SELCTED_GROUP_MODAL, false));
      dispatch(fetchUnreconciledVouchers(companyId, partyId, UNRECONCILED_VOUCHER_STATEMENT));
      dispatch(
        fetchAccountReconciledVouchersGroupByDate(
          companyId,
          partyId,
          'reconciledVouchersGroupByDate',
          1
        )
      );
      dispatch(
        fetchUnreconciledVouchers(
          companyId,
          partyId,
          UNRECONCILED_VOUCHERS,
          getCurrentPage(unreconcileDataCount, RECONCILE_MODAL_PAGE_SIZE),
          RECONCILE_MODAL_PAGE_SIZE
        )
      );
      dispatch(
        fetchInProgressVouchers(
          companyId,
          partyId,
          'inProgressVouchers', //Right now this parameter is not used in reducer (storeKey)
          getCurrentPage(inProgressDataCount, RECONCILE_MODAL_PAGE_SIZE)
        )
      );
    })
    .catch(() => {
      dispatch({ type: RECONCILE_VOUCHERS_FAILED, key: storeKey, payload: CONST_ERROR_MESSAGE });
      callback && callback();
    });
};

export const viewVoucherSummary = pathname => dispatch => {
  navigate(dispatch, pathname);
};

export const fetchPartyRelationTypes = () => dispatch => {
  dispatch({ type: FETCH_PARTY_RELATION_TYPES_INITIATED });
  api
    .fetchPartyRelationTypes()
    .then(response => {
      dispatch({ type: FETCH_PARTY_RELATION_TYPES_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_PARTY_RELATION_TYPES_FAILED, payload: error }));
};

export const fetchTransporterParties = () => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchTransporterParties(id)
    .then(response => {
      dispatch({ type: FETCH_TRANSPORTER_PARTY_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_TRANSPORTER_PARTY_FAILED, payload: error }));
};

export const resetPartyContacts = () => ({ type: FETCH_CONTACTS_SUCCESS, payload: [] });

export const fetchPartyContacts = partyId => (dispatch, getState) => {
  dispatch({ type: FETCH_CONTACTS_REQUESTED, payload: {} });
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchPartyContacts(id, partyId)
    .then(response => {
      dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: FETCH_CONTACTS_FAILED, payload: error }));
};

export const handleContactSearch = (
  pageNo = 1,
  searchTextParam = '',
  searchBy = '',
  includeRelatedParties = false,
  infiniteScroll = true
) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (searchTextParam) {
    query += `searchText=${searchTextParam}&`;
  }
  if (searchBy) {
    query += `searchBy=${searchBy}`;
  }
  if (includeRelatedParties) {
    query += `includeRelatedParties=${includeRelatedParties}`;
  }

  dispatch({ type: FETCH_CONTACTS_SEARCH_REQUESTED });
  api
    .contactSearch(id, pageNo, query)
    .then(response => {
      dispatch({ type: FETCH_CONTACTS_SEARCH_SUCCESS, payload: response, infiniteScroll });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: FETCH_CONTACTS_SEARCH_FAILED, payload: error });
    });
};

export const updatePartyNameInDrawer = (name, modalKey) => dispatch => {
  //console.log(name);
  dispatch({ type: UPDATE_NAME_IN_DRAWER, name: name, modalKey: modalKey });
};

// export const fetchReconciledVouchersGroupByDate = (companyId, partyId, storeKey) => dispatch => {
//   dispatch({ type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED, key: storeKey });
//   api
//     .fetchReconciledVouchersGroupByDate(companyId, partyId)
//     .then(response => {
//       dispatch({
//         type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS,
//         key: storeKey,
//         payload: response
//       });
//     })
//     .catch(error =>
//       dispatch({ type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED, key: storeKey, payload: error })
//     );
// };

export const fetchGstnDetails = (gstn, callback) => dispatch => {
  dispatch({ type: FETCH_GSTN_DETAILS_REQUESTED, payload: {} });

  api
    .fetchGstnDetails(gstn)
    .then(response => {
      dispatch({ type: FETCH_GSTN_DETAILS_SUCCESS, payload: response });
      if (callback) callback(response);
    })
    .catch(error => {
      dispatch({ type: FETCH_GSTN_DETAILS_FAILED, payload: error });
      dispatch(openSnackbar('Please check the GSTIN entered', ERROR));
    });
};

export const fetchPartySalesDiscount = (partyId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_PARTY_SALES_DISCOUNT_REQUESTED, payload: {} });

  api
    .fetchPartySalesDiscount(iCompanyId, partyId)
    .then(response => {
      dispatch({ type: FETCH_PARTY_SALES_DISCOUNT_SUCCESS, payload: response, partyId });
      dispatch(recalculateVoucher(PARTY_SELECT));
      if (callback) callback();
    })
    .catch(error => {
      dispatch({ type: FETCH_PARTY_SALES_DISCOUNT_FAILED, payload: error });
    });
};

export const fetchPartyPurchaseDiscount = (partyId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_PARTY_PURCHASE_DISCOUNT_REQUESTED, payload: {} });

  api
    .fetchPartyPurchaseDiscount(iCompanyId, partyId)
    .then(response => {
      dispatch({ type: FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS, payload: response, partyId });
      dispatch(recalculateVoucher(PARTY_SELECT));
      if (callback) callback();
    })
    .catch(error => {
      dispatch({ type: FETCH_PARTY_PURCHASE_DISCOUNT_FAILED, payload: error });
    });
};

export const toggleVoucherViewMode = (
  flag,
  voucher,
  type,
  id,
  modelKeyObj = { key: 'VOUCHER' },
  duplicateVoucherType
) => dispatch => {
  dispatch(resetSelectedVoucher(voucher));

  dispatch({
    type: HANDLE_TOGGLE_VOUCHER_VIEW_MODE,
    flag,
    voucher,
    voucherType: type,
    id,
    modelKeyObj,
    duplicateVoucherType
  });
};

export const setDuplicateVoucherType = voucherType => dispatch => {
  dispatch({ type: SET_DUPLICATE_VOUCHER_TYPE, duplicateVoucherType: voucherType });
};

export const handlePrintReconciled = (partyId, date) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .handlePreviewReconciledPrint(companyId, partyId, date)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const handlePrintUnreconciled = partyId => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .handlePreviewUnreconciledPrint(companyId, partyId)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const addPartyFieldData = (data = {}) => dispatch => {
  dispatch({ type: ADD_PARTY_PICKER_FIELD_DATA, data });
};

export const updatePartyInField = response => (dispatch, getState) => {
  const {
    contacts: {
      partyPickerField: { key, partyProfileKey = PARTY_PROFILE },
      partyProfile: { id: partyId }
    },
    currentCompany: { id: companyId }
  } = getState();
  if (key === VOUCHER_PARTY_PICKER) {
    //update party picker in voucher
    dispatch({
      type: UPDATE_BUSINESS_CONTACT,
      payload: {
        business: { ...response }
      }
    });
    dispatch(fetchPartyProfile(response.id, partyProfileKey));
  } else if (key === ADD_TRANSPORTER_TAB_PARTY_PICKER) {
    //update transporter in add party form - logic handled withn the add form itself
  } else if (key === EDIT_TRANSPORTER_TAB_PARTY_PICKER) {
    //update transporter in edit party
    dispatch(addPartyTransporter(companyId, partyId, response.id, partyProfileKey));
  } else if (key === ITEM_FORM) {
    //update transporter in edit party
    dispatch(setItemProperty('party', response));
    dispatch(setItemPartyId(response.id));
  } else {
    dispatch({
      //default case
      type: UPDATE_BUSINESS_CONTACT,
      payload: {
        business: { ...response }
      }
    });
    dispatch(fetchPartyProfile(response.id, partyProfileKey));
  }
  dispatch({ type: ADD_PARTY_PICKER_FIELD_DATA, data: {} });
};

export const fetchPartyRefAccountId = (companyId, partyId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  api
    .fetchPartyRefAccount(id, partyId)
    .then(response => {
      dispatch({ type: FETCH_ACCOUNT_ID_SUCCESS, refAccountId: response.id });
      callback && callback(response.id);
    })
    .catch(error => dispatch({ type: FETCH_PARTY_ACCOUNT_ID_FAILED, payload: error }));
};

//accounts related

export const fetchAccountStatement = (
  companyId,
  accountId,
  queryStartDate,
  queryEndDate,
  storeKey,
  branchesString = null
) => (dispatch, getState) => {
  const {
    contacts: {
      selectedFilterDates: { startDate, endDate }
    }
  } = getState();
  let tempStartDate = queryStartDate || startDate;
  let tempEndDate = queryEndDate || endDate;
  dispatch({ type: STATEMENT_LOADING, key: storeKey });

  api
    .fetchAccountStatement(companyId, accountId, tempStartDate, tempEndDate, branchesString)
    .then(response => {
      response.startDate = tempStartDate;
      response.endDate = tempEndDate;
      dispatch({ type: STATEMENT_SUCCESS, key: storeKey, payload: response });
    })
    .catch(error => {
      dispatch({ type: STATEMENT_FAILED, key: storeKey, payload: error });
    });
};

export const exportFullAccountStatement = (
  companyId,
  accountId,
  page = 1,
  branchesString = null
) => (dispatch, getState) => {
  const {
    reports: {
      filters: { startDate, endDate, selectedBranches, includeType, selectedSalesPerson }
    },
    currentCompany
  } = getState();
  let tempStartDate = startDate;
  let tempEndDate = endDate;
  let statementType = 'all';
  let iBranchIds = '';
  if (selectedBranches && selectedBranches.length > 0) {
    iBranchIds = `&iBranchIds=${getValuesByKey(selectedBranches).toString()}`;
  }
  let include = setIncludeTypeQuery(includeType, '');

  api
    .exportAccountStatement(
      companyId || currentCompany.id,
      accountId,
      tempStartDate,
      tempEndDate,
      iBranchIds,
      statementType,
      include,
      page,
      selectedSalesPerson
    )
    .then(response => {
      dispatch({
        type: PROGRESS_TRACKER,
        payload: {
          percentage: response.currentStatusInPercentage,
          isCompleted: response.isCompleted
        }
      });
      dispatch(trackExportStatus(response.id));
    })
    .catch(error => {
      dispatch({ type: REPORTS_DATA_FAILED });
    });
};

export const handleAccountPrintReconciled = (accountId, date) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .handleAccountPreviewReconciledPrint(companyId, accountId, date)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const handleAccountPrintUnreconciled = accountId => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .handleAccountPreviewUnreconciledPrint(companyId, accountId)
    .then(res => {
      window.open(res.data.response, '_blank').focus();
      return res;
    })
    .catch(error => {
      serverError(error);
    });
};

export const fetchAccountUnreconciledVouchers = (companyId, accountId, storeKey) => dispatch => {
  dispatch({ type: FETCH_UNRECONCILED_VOUCHERS_INITIATED, key: storeKey });
  api
    .fetchAccountUnreconciledVouchers(companyId, accountId)
    .then(response => {
      dispatch({ type: FETCH_UNRECONCILED_VOUCHERS_SUCCESS, key: storeKey, payload: response });
      dispatch({ type: RECONCILE_VOUCHERS_ERROR_RESET, key: storeKey, payload: '' });
    })
    .catch(error =>
      dispatch({ type: FETCH_UNRECONCILED_VOUCHERS_FAILED, key: storeKey, payload: error })
    );
};

export const accountReconcileVouchers = (
  companyId,
  accountId,
  data,
  storeKey,
  modalKey,
  action,
  callback,
  selectionMode = 'SELECTIVE'
) => dispatch => {
  dispatch(
    reconcileVouchers(
      companyId,
      accountId,
      data,
      storeKey,
      modalKey,
      action,
      callback,
      selectionMode
    )
  );
};

export const fetchAccountReconciledVouchersGroupByDate = (
  companyId,
  accountId,
  storeKey,
  page = 1
) => dispatch => {
  dispatch({ type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED, key: storeKey });
  api
    .fetchAccountReconciledVouchersGroupByDate(companyId, accountId, page)
    .then(response => {
      dispatch({
        type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS,
        key: storeKey,
        payload: response
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED,
        key: storeKey,
        payload: error
      });
      serverError(error);
    });
};

export const setRefAccountId = accountId => dispatch => {
  dispatch({ type: FETCH_ACCOUNT_ID_SUCCESS, refAccountId: accountId });
};

export const setStatementBranchFilter = branches => dispatch => {
  dispatch({ type: SET_STATEMENT_BRANCH_FILTER, branches });
};

export const setStatementIncludeTypeFilter = (key, value) => dispatch => {
  dispatch({ type: SET_STATEMENT_INCLUDE_TYPE_FILTER, key: key, value: value });
};

export const setIntervalFilter = interval => dispatch => {
  dispatch({ type: SET_INTERVAL, payload: interval });
};

export const setDateFilter = (startDate, endDate) => dispatch => {
  dispatch({
    type: SET_FILTER_DATES,
    payload: {
      startDate,
      endDate
    }
  });
};

export const setReconcileDate = date => ({ type: SET_RECONCILE_DATE, payload: date });

export const setPartyListFilter = (value, key, storeKey = PARTY_LIST_DATA) => dispatch => {
  dispatch({ type: SET_PARTY_ACCOUNT_LIST_FILTER, value, key, storeKey });
};

export const resetPartyListFilter = (storeKey = PARTY_LIST_DATA) => dispatch => {
  dispatch({ type: RESET_PARTY_ACCOUNT_LIST_FILTER, storeKey });
};

export const resetPartyFilters = callback => (dispatch, getState) => {
  const {
    currentCompany: {
      selectedFinancialYear: { startDate, endDate }
    }
  } = getState();
  dispatch({
    type: RESET_PARTY_FILTERS,
    payload: {
      startDate,
      endDate
    }
  });
  callback && callback();
};

export const fetchAccountsCurrentBalance = (accountId, endDate, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id }
  } = getState();
  dispatch({
    type: GET_CURRENT_BALANCE_REQUESTED
  });
  let query = '';
  if (endDate) {
    query += `endDate=${getEndTimeOfDay(endDate)}`;
  }
  return api
    .accountCurrentBalance(id, accountId, query)
    .then(response => {
      dispatch({ type: GET_CURRENT_BALANCE_SUCCESS, response: response.currentBalance });
      callback && callback(response);
    })
    .catch(err => {
      dispatch({
        type: GET_CURRENT_BALANCE_FAILED
      });
      callback && callback({ currentBalance: 0 });
      console.log(err);
    });
};

export const handlePartyTagSelect = (key, tags) => dispatch => {
  dispatch({ type: HANDLE_CHANGE_PARTY_TAGS, tags, key });
};

export const fetchPartyTags = () => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: FETCH_PARTY_TAGS_REQUESTED });
  api
    .getPartyTags(iCompanyId)
    .then(response => {
      dispatch({
        type: FETCH_PARTY_TAGS_SUCCESS,
        payload: response
      });
    })
    .catch(() => {
      dispatch({ type: FETCH_PARTY_TAGS_FAILED });
    });
};

export const createPartyTag = (key, payload) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: ADD_PARTY_TAG_REQUESTED });
  api
    .postPartyTag(iCompanyId, payload)
    .then(response => {
      dispatch({ type: ADD_PARTY_TAG_SUCCESS, payload: response });
      dispatch({ type: ADD_PARTY_TAG_IN_SELECTED, payload: response, key });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: ADD_PARTY_TAG_FAILED });
    });
};

export const updatePartyTag = (payload, key, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: UPDATE_PARTY_TAG_REQUESTED });
  api
    .updatePartyTag(iCompanyId, payload.id, payload)
    .then(response => {
      dispatch({ type: UPDATE_PARTY_TAG_SUCCESS, payload: response });
      dispatch({ type: UPDATE_PARTY_TAG_IN_SELECTED, payload: response, key });
      // dispatch(fetchPartysMaster(itemsByGroupPageNo));
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: UPDATE_PARTY_TAG_FAILED });
    });
};

export const deletePartyTag = (tagId, key, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      [key]: partyList //{ page }
    }
  } = getState();
  dispatch({ type: DELETE_PARTY_TAG_REQUESTED });
  api
    .deletePartyTag(iCompanyId, tagId)
    .then(() => {
      dispatch({ type: DELETE_PARTY_TAG_SUCCESS, tagId });
      dispatch({ type: DELETE_PARTY_TAG_IN_SELECTED, tagId, key });
      key !== PARTY_LIST_DATA &&
        partyList &&
        dispatch(handlePartySearch(null, partyList.page, '', '', key));
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: DELETE_PARTY_TAG_FAILED });
    });
};

export const updateTagsInParties = (partyIds, tags, key = PARTY_LIST_DATA, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();
  dispatch({ type: ADD_TAGS_TO_PARTIES_REQUESTED });
  api
    .addTagsToParties(iCompanyId, partyIds, tags)
    .then(response => {
      dispatch({ type: ADD_TAGS_TO_PARTIES_SUCCESS, tags: response, key });
      // dispatch({ type: ADD_TAGS_TO_PARTIES_IN_SELECTED, payload: response });
      // dispatch(handlePartySearch());
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: ADD_TAGS_TO_PARTIES_FAILED });
    });
};

export const removeTagsFromParty = (partyIds, tags, callback) => (dispatch, getState) => {
  dispatch({ type: REMOVE_TAGS_FROM_PARTY_REQUESTED });
  const {
    currentCompany: { id: iCompanyId }
    // items: { [storeKey]: tags }
  } = getState();
  const tagIds = getValuesByKey(tags).toString();
  let query = `?tagId=${tagIds}`;
  query += `&businessId=${getValuesByKey(partyIds).toString()}`;
  api
    .removeTagsFromParty(iCompanyId, query)
    .then(response => {
      dispatch({ type: REMOVE_TAGS_FROM_PARTY_SUCCESS });
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: REMOVE_TAGS_FROM_PARTY_FAILED });
    });
};

export const editTagsFromParty = (partyIds, removeList, addList, key, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id: iCompanyId },
    contacts: {
      partyProfile: { id }
    }
  } = getState();
  if (removeList.length > 0 && addList.length > 0) {
    dispatch({ type: ADD_TAGS_TO_PARTIES_REQUESTED });
    api
      .addTagsToParties(iCompanyId, partyIds, addList)
      .then(response => {
        dispatch({ type: ADD_TAGS_TO_PARTIES_SUCCESS, tags: response, key });
        dispatch(removeTagsFromParty([{ id }], removeList, callback));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: ADD_TAGS_TO_PARTIES_FAILED });
      });
  } else {
    if (removeList.length > 0) {
      dispatch(removeTagsFromParty([{ id }], removeList, callback));
    }

    if (addList.length > 0) {
      dispatch({ type: ADD_TAGS_TO_PARTIES_REQUESTED });
      api
        .addTagsToParties(iCompanyId, partyIds, addList)
        .then(response => {
          dispatch({ type: ADD_TAGS_TO_PARTIES_SUCCESS, tags: response, key });
          // dispatch({ type: ADD_TAGS_TO_PARTIES_IN_SELECTED, payload: response });
          // dispatch(handlePartySearch());
          callback && callback();
        })
        .catch(error => {
          console.log(error);
          dispatch({ type: ADD_TAGS_TO_PARTIES_FAILED });
        });
    }
  }
};

export const exportParties = callBack => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  api
    .exportParties(iCompanyId, {})
    .then(response => {
      getStatus(dispatch, response.id, 'party');
      callBack && callBack();
    })
    .catch(error => {
      console.log(error);
    });
};

export const importParties = (url, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  return api
    .importParties(iCompanyId, url)
    .then(response => {
      getStatus(dispatch, response.id, 'importParty');
      callback && callback();
    })
    .catch(error => {
      callback && callback();
      console.log(error);
    });
};

export const importItems = (url, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  return api
    .importItems(iCompanyId, url)
    .then(response => {
      getStatus(dispatch, response.id, 'importItems');
      callback && callback();
    })
    .catch(error => {
      callback && callback();
      console.log(error);
    });
};

export const updateItemViaImport = (url, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  return api
    .updateItemViaImport(iCompanyId, url)
    .then(response => {
      getStatus(dispatch, response.id, 'updateItemViaImport');
      callback && callback();
    })
    .catch(error => {
      callback && callback();
      console.log(error);
    });
};

export const importItemsOpeningStock = (url, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  return api
    .importItemsOpeningStock(iCompanyId, url)
    .then(response => {
      getStatus(dispatch, response.id, 'importItemsOpeningStock');
      callback && callback();
    })
    .catch(error => {
      let errors = error && error.response && error.response.data;
      let errorMessageList = '';
      errors &&
        errors.error &&
        errors.error.details &&
        errors.error.details.map(
          (err, _) => (errorMessageList = errorMessageList + `${err.message} `)
        );
      errorMessageList += errors && errors.error && errors.error.message;
      dispatch(openSnackbar(errorMessageList, ERROR));
      callback && callback();
    });
};

export const handlePartyCreationMode = () => (dispatch, getState) => {
  const {
    contacts: { quickPartyCreation }
  } = getState();
  dispatch({ type: HANDLE_PARTY_CREATION_MODE, flag: !quickPartyCreation });
  dispatch(updateUserVoucherPartySettings());
};

export const quickPartyCreate = (data, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: iCompanyId }
  } = getState();

  return api
    .quickPartyCreate(iCompanyId, data)
    .then(response => {
      callback && callback(response, true);
    })
    .catch(error => {
      callback && callback(error, false);
      serverError(error);
    });
};

export const getAccountInterestStatement = (accountId, pageNo, rate = 0, callback) => (
  dispatch,
  getState
) => {
  const {
    currentCompany: { id },
    contacts: {
      selectedInterestCalculationFilterDates: { startDate, endDate, selectedBranch },
      includeType
    }
  } = getState();

  let query = `startDate=${getFormattedDateIso(moment(startDate))}&endDate=${getFormattedDateIso(
    moment(endDate)
  )}&interestRate=${rate}`;

  if (includeType) {
    query = setIncludeTypeQuery(includeType, query);
  }

  if (selectedBranch.length > 0) {
    query += `&iBranchIds=${getValuesByKey(selectedBranch).toString()}`;
  }

  dispatch({ type: FETCH_INTEREST_CALCULATION_REQUESTED });

  return api
    .getAccountInterestStatement(id, accountId, pageNo, query)
    .then(response => {
      dispatch({ type: FETCH_INTEREST_CALCULATION_SUCCESS });
      callback && callback(response);
    })
    .catch(error => {
      dispatch({ type: FETCH_INTEREST_CALCULATION_FAILED });
      serverError(error);
    });
};

export const setInterestCalculationDateFilter = (startDate, endDate) => dispatch => {
  dispatch({ type: SET_INTEREST_CALCULATION_DATE, startDate, endDate });
};

export const setInterestCalculationDateInterval = selectedInterval => dispatch => {
  dispatch({ type: SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE, selectedInterval });
};

export const resetInterestCalcFilters = () => dispatch => {
  dispatch({ type: RESET_INTEREST_CALCULATION_FILTERS });
};

export const setInterestCalculationBranchFilter = branch => dispatch => {
  dispatch({ type: SET_INTEREST_CALCULATION_BRANCH, branch });
};
